<script setup lang="ts">
import type { PaddingType, SpacingOption } from '@gem/control/src/control/distance-picker/types';
import type { ScreenType } from '../../types';
import FieldLabel from '../FieldLabel.vue';
import InputUnitWithOptions from './InputUnitWithOptions.vue';
import PaddingSetting from './PaddingSetting.vue';
import { computed, provide, ref } from 'vue';
import useUpdateDataSizeSetting from './useUpdateDataSizeSetting';
import Select from '../Select.vue';
import parseUnit from '@gem/control/src/helpers/parseUnit';

import type {
  ShapeOptions,
  SettingID,
  SizeSettingValue,
  SettingConfig,
  ShapeOptionKeyword,
} from '@gem/control/src/control/size-setting/type';
import { InputUpDownNumber, type ObjectDevices } from '@gem/control';
import { isPercent, reduceRatio } from '../../helpers/globalSize';

type SizeSettingProps = {
  id: string;
  value?: SizeSettingValue;
  currentScreen?: ScreenType;
  hiddenSettings?: SettingID[];
  settingConfig?: Record<SettingID, SettingConfig>;
  globalStyleContainerWidth?: string;
  globalSpacingValues: SpacingOption[];
  globalSpacing?: Record<string, ObjectDevices<string>>;
  isShowResponsive?: boolean;
  hiddenShowMore?: boolean;
  collapsed?: boolean;
  lockShapeValue?: boolean;
  sizePaddingInput?: 'small' | 'normal';
  disableShapeLinkedTooltip?: string;
};

const props = withDefaults(defineProps<SizeSettingProps>(), {
  sizePaddingInput: 'normal',
});

const emit = defineEmits<{
  (e: 'onChange', controlId?: string, value?: SizeSettingValue, key?: string): void;
  (e: 'change', controlId?: string, value?: SizeSettingValue, key?: string): void;
  (e: 'changeScreen', screenId: ScreenType): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'onClickCollapse', value: boolean): void;
}>();

const collapsed = computed(() => props.collapsed);

const inputWidthFocus = ref<boolean>(false);
const shapeLinked = computed(() =>
  props.lockShapeValue
    ? props.value?.shapeLinked
    : props.value?.shapeLinked || props.value?.shape !== 'custom' || false,
);
const isDisableLink = computed(
  () =>
    (props.value?.height?.includes('px') && isPercent(props.value?.width || '')) ||
    props.value?.height === 'fit-content' ||
    props.value?.height === '100vh' ||
    props.value?.disableShapeLinked,
);
const widthHeightLinked = computed(() => props.value?.widthHeightLinked);

provide('globalSpacing', props.globalSpacing);
provide('currentScreen', props.currentScreen);

const hiddenButtonShowMore = computed(
  () => props.hiddenShowMore || (isHiddenSetting('padding') && isHiddenSetting('gap')),
);

const shapeOptions = computed(() => {
  const shapeValueCustom = props.value?.customShapeValue;
  const shapeDisplayOptions = props.settingConfig?.shape?.displayOptions;
  const options = [
    {
      label: 'Square',
      value: 'square',
      type: 'image-shape',
      icon: 'image-shape-square',
      tooltip: 'Square',
      keyword: 'square',
    },
    {
      label: 'Vertical',
      value: 'vertical',
      type: 'image-shape',
      icon: 'image-shape-vertical',
      tooltip: 'Vertical landscape',
      keyword: 'vertical',
    },
    {
      label: 'Horizontal',
      value: 'horizontal',
      type: 'image-shape',
      icon: 'image-shape-horizontal',
      tooltip: 'Horizontal landscape',
      keyword: 'horizontal',
    },
    {
      label: props.settingConfig?.shape?.shapeTitleConfig?.original || 'Original',
      value: 'original',
      type: 'image-shape',
      keyword: 'original',
    },
    {
      label: 'Custom',
      des: shapeValueCustom ? reduceRatio(shapeValueCustom) : '',
      value: 'custom',
      baseIcon: 'customize-layout',
      tooltip: 'Custom size',
      keyword: 'custom',
    },
  ];
  return shapeDisplayOptions?.length
    ? options.filter((item) => shapeDisplayOptions.includes(item.keyword as ShapeOptionKeyword))
    : options;
});

const previousShapeValue = ref(props.value?.shape);

const { getValueWhenUpdateSetting } = useUpdateDataSizeSetting(computed(() => props));

const emitChange = (type: 'change' | 'onChange', data: SizeSettingValue, key?: string) => {
  if (type === 'change') emit('change', props.id, data, key);
  else emit('onChange', props.id, data, key);
};

const checkIsChangeOnInput = (type: 'change' | 'onChange', value?: string) => {
  return (!value && props.value?.shape === 'custom' && type === 'change') || (value && props.value?.shape !== 'custom');
};

const handleUpdateHeight = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('height', value, previousShapeValue, type);
  if (widthHeightLinked.value) {
    newData.width = newData.height;
  }
  if (!props.lockShapeValue && checkIsChangeOnInput(type, value)) {
    emitChange('change', newData, 'height');
    return;
  }
  emitChange(type, newData, 'height');
};

const handleUpdateShape = (type: 'change' | 'onChange', value?: ShapeOptions) => {
  previousShapeValue.value = value;
  inputWidthFocus.value = value === 'custom';
  const newData = getValueWhenUpdateSetting('shape', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateWidth = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('width', value, previousShapeValue, type);
  if (widthHeightLinked.value) {
    newData.height = newData.width;
  }
  if (!props.lockShapeValue && checkIsChangeOnInput(type, value)) {
    emitChange('change', newData, 'width');
    return;
  }
  emitChange(type, newData, 'width');
};

const handleUpdatePadding = (type: 'change' | 'onChange', value?: PaddingType) => {
  const newData = getValueWhenUpdateSetting('padding', value, previousShapeValue);
  emitChange(type, newData, 'padding');
};

const handleUpdateGap = (type: 'change' | 'onChange', value?: string) => {
  const newData = getValueWhenUpdateSetting('gap', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateShapeLinked = (type: 'change' | 'onChange', value?: boolean) => {
  const newData = getValueWhenUpdateSetting('shapeLinked', value, previousShapeValue);
  emitChange(type, newData);
};

const handleUpdateData = (type: 'change' | 'onChange', settingID: string, value?: string | PaddingType | boolean) => {
  switch (settingID) {
    case 'height':
      handleUpdateHeight(type, value as string);
      break;
    case 'width':
      handleUpdateWidth(type, value as string);
      break;
    case 'shape':
      handleUpdateShape(type, value as ShapeOptions);
      break;
    case 'padding':
      handleUpdatePadding(type, value as PaddingType);
      break;
    case 'gap':
      handleUpdateGap(type, value as string);
      break;
    case 'shapeLinked':
      handleUpdateShapeLinked(type, value as boolean);
      break;
  }
};

const handleChange = (settingID: string, value?: string | PaddingType) => {
  if (settingID === 'width' && value && value !== 'default' && value.toString().toLowerCase() !== 'auto') {
    const [_, unit] = parseUnit(value as string);
    if (!widthUnits.value.includes(unit)) {
      value = `${value}px`;
    }
  }

  handleUpdateData('change', settingID, value);
};

const handleOnChange = (settingID: string, value?: string | PaddingType) => {
  handleUpdateData('onChange', settingID, value);
};

const handleLinkWidthHeight = () => {
  if (widthHeightLinked.value) return;
  const link = !shapeLinked.value;
  handleUpdateData('change', 'shapeLinked', link);
};

function isHiddenSetting(settingID: SettingID) {
  if (settingID === 'shape' && props.value?.hiddenShape !== undefined) {
    return props.value?.hiddenShape;
  }
  return props.hiddenSettings?.includes(settingID);
}

const onClickSubAction = (type: string, value?: any) => {
  emit('onClickSubAction', type, value);
};

const widthUnits = computed(() => {
  return props.settingConfig?.width?.units ? (props.settingConfig?.width?.units as string[]) : ['px', '%'];
});
const padding = computed(() => {
  return props.value?.padding;
});
</script>
<template>
  <div
    v-if="!isHiddenSetting('shape') && !isHiddenSetting('proportion')"
    class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
    <FieldLabel
      :label="settingConfig?.shape?.name || 'Proportion'"
      :has-device="isShowResponsive"
      :current-screen="currentScreen"
      @change-screen="$emit('changeScreen', $event)" />
    <div class="max-w-input-horizontal w-full">
      <Select id="shape" disable-default :options="shapeOptions" :value="value?.shape" @control-change="handleChange" />
    </div>
  </div>
  <div v-if="!isHiddenSetting('width') || !isHiddenSetting('height')" class="relative">
    <div
      v-if="!isHiddenSetting('width')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.width?.name || 'Width'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <InputUnitWithOptions
        v-if="!value?.isDropDownWidth"
        id="width"
        :units="widthUnits"
        :value="value?.width"
        :size-config="settingConfig?.width?.sizeConfig"
        :display-options="settingConfig?.width?.displayOptions"
        :global-style-container-width="globalStyleContainerWidth"
        :focus="inputWidthFocus"
        @change="handleChange"
        @on-change="handleOnChange" />
      <div v-if="value?.isDropDownWidth" class="max-w-input-horizontal flex w-full flex-col gap-16">
        <InputUpDownNumber
          id="width"
          :value="parseFloat(value?.width || '16')"
          :min="0"
          :control-change="handleChange"
          @control-on-change="handleOnChange" />
      </div>
    </div>
    <div
      v-if="!isHiddenSetting('height')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-center justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.height?.name || 'Height'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <InputUnitWithOptions
        id="height"
        :units="['px']"
        :value="value?.height"
        :size-config="settingConfig?.height?.sizeConfig"
        :display-options="settingConfig?.height?.displayOptions"
        :global-style-container-width="globalStyleContainerWidth"
        @change="handleChange"
        @on-change="handleOnChange" />
    </div>
    <div
      v-if="!isHiddenSetting('shape') && !isHiddenSetting('height') && !isHiddenSetting('width') && !widthHeightLinked"
      class="absolute top-[18px] right-[144px] flex items-center">
      <div class="flex h-[52px] w-24 flex-col items-end justify-between">
        <div class="border-dark-300 h-12 w-12 rounded-tl-[8px] border-t border-l"></div>
        <g-tooltip content-class="w-[180px] whitespace-normal !mt-[-20px]" placement="top" :disabled="!isDisableLink">
          <GButtonV2
            size="small"
            icon-view-box="0 0 16 16"
            :type="shapeLinked ? 'secondary' : 'ghost'"
            :only-icon="shapeLinked ? 'spacing-link' : 'spacing-un-link'"
            :disable="isDisableLink"
            @click="handleLinkWidthHeight()">
          </GButtonV2>
          <template #content>
            <div class="text-12">
              {{ disableShapeLinkedTooltip || "Use 'px' for width and height to keep the proportions when resizing" }}
            </div>
          </template>
        </g-tooltip>
        <div class="border-dark-300 h-12 w-12 rounded-bl-[8px] border-b border-l"></div>
      </div>
    </div>
    <div
      v-if="!isHiddenSetting('height') && !isHiddenSetting('width') && widthHeightLinked && isHiddenSetting('shape')"
      class="absolute top-[18px] right-[144px] flex items-center">
      <div class="flex h-[52px] w-24 flex-col items-end justify-between">
        <div class="border-dark-300 h-12 w-12 rounded-tl-[8px] border-t border-l"></div>
        <GButtonV2 type="secondary" only-icon="spacing-link" icon-view-box="0 0 16 16" size="small"></GButtonV2>
        <div class="border-dark-300 h-12 w-12 rounded-bl-[8px] border-b border-l"></div>
      </div>
    </div>
  </div>
  <template v-if="hiddenButtonShowMore ? true : !collapsed">
    <div
      v-if="!isHiddenSetting('padding')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-start justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.padding?.name || 'Padding'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <div class="max-w-input-horizontal w-full">
        <PaddingSetting
          id="padding"
          :value="padding"
          :size-padding-input="sizePaddingInput"
          :global-spacing-values="globalSpacingValues"
          :padding-config="settingConfig?.padding?.paddingConfig"
          :on-click-sub-action="onClickSubAction"
          :current-screen="currentScreen"
          @control-change="handleChange"
          @control-on-change="handleOnChange" />
      </div>
    </div>
    <div
      v-if="!isHiddenSetting('gap')"
      class="gemx-control mb-16 flex min-h-[36px] w-full items-start justify-between gap-16">
      <FieldLabel
        :label="settingConfig?.gap?.name || 'Gap'"
        :has-device="isShowResponsive"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <div class="max-w-input-horizontal w-full">
        <InputUnitWithOptions
          id="gap"
          :units="['px']"
          :value="value?.gap ?? ''"
          :size-config="settingConfig?.gap?.sizeConfig"
          :display-options="settingConfig?.gap?.displayOptions"
          :global-style-container-width="globalStyleContainerWidth"
          @change="handleChange"
          @on-change="handleOnChange" />
      </div>
    </div>
  </template>
  <GButtonV2
    v-if="!hiddenButtonShowMore"
    type="secondary"
    size="medium"
    button-width="full"
    icon-view-box="0 0 14 14"
    :icon-after="collapsed ? 'chevron-down' : 'chevron-up'"
    @click="emit('onClickCollapse', !collapsed)">
    {{ collapsed ? 'Show more' : 'Show less' }}
  </GButtonV2>
</template>
