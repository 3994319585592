<script lang="ts" setup>
import { ref, provide } from 'vue';
import type { PaddingType, SpacingOption, Spacing } from './types';
import { StateListKey, MouseUpKey } from './keys';
import Inside from './Inside.vue';
import Outside from './Outside.vue';
import OutsideTriangle from './OutsideTriangle.vue';
import InsideTriangle from './InsideTriangle.vue';
import { onClickOutside } from '@vueuse/core';

type Props = {
  id?: string;
  label?: string;
  margin?: PaddingType;
  padding?: PaddingType;
  inside?: boolean;
  link?: boolean;
  min?: number;
  isOnChangingMargin?: boolean;
  isOnChangingPadding?: boolean;
  options: SpacingOption[];
  controlChange?: (id: string, value?: string) => void;
};

const props = defineProps<Props>();

const isHoverOutside = ref(false);
const isHoverInside = ref(false);
const isOpenSelectSpacing = ref(false);

const initialFillOutside = {
  'margin-top': '#212121',
  'margin-right': '#212121',
  'margin-left': '#212121',
  'margin-bottom': '#212121',
};

const initialFillInside = {
  'padding-top': '#212121',
  'padding-right': '#212121',
  'padding-left': '#212121',
  'padding-bottom': '#212121',
};

type SpacingProperty = {
  property: string;
  class: string;
  pos: keyof PaddingType;
};

const buildInsideProperty: SpacingProperty[] = [
  {
    property: 'padding-top',
    class: 'top-0 ',
    pos: 'top',
  },
  {
    property: 'padding-left',
    class: 'top-0 left-0',
    pos: 'left',
  },
  {
    property: 'padding-bottom',
    class: 'bottom-0',
    pos: 'bottom',
  },
  {
    property: 'padding-right',
    class: 'right-0 ',
    pos: 'right',
  },
];

const buildOutsideProperty: SpacingProperty[] = [
  {
    property: 'margin-top',
    class: 'top-0 ',
    pos: 'top',
  },
  {
    property: 'margin-left',
    class: 'top-0 left-0',
    pos: 'left',
  },
  {
    property: 'margin-bottom',
    class: 'bottom-0',
    pos: 'bottom',
  },
  {
    property: 'margin-right',
    class: 'right-0',
    pos: 'right',
  },
];

const emit = defineEmits<{
  (e: 'controlOnChange', property: keyof Spacing, value?: string): void;
  (e: 'onClickSubAction', type: string, value?: any): void;
  (e: 'changeLink'): void;
}>();

const target = ref(null);
const insidePropertyDiffList = ref<Record<string, string>>({});
const outsidePropertyDiffList = ref<Record<string, string>>({});
const fillOutside = ref<Record<string, string>>(initialFillOutside);
const fillInside = ref<Record<string, string>>(initialFillInside);
const stateList = ref<Record<string, boolean>>({});
const mouseUpList = ref<Record<string, boolean>>({});
provide(StateListKey, { stateList: stateList, changeStateListOnFocus });
provide(MouseUpKey, { mouseUp: mouseUpList, detectMouseUp });

onClickOutside(target, () => {
  fillOutside.value = initialFillOutside;
  fillInside.value = initialFillInside;
  isHoverOutside.value = false;
  isHoverInside.value = false;
  stateList.value = {};
});

function detectMouseUp(property: string) {
  mouseUpList.value = {
    ...mouseUpList.value,
    [property]: !mouseUpList.value[property],
  };
}
function changeLink() {
  emit('changeLink');
}

function controlChangeProp(property: keyof Spacing, value?: string): void {
  emit('controlOnChange', property, value);
}

function onControlChange(property: keyof Spacing, value?: string): void {
  props.controlChange?.(property, value);
}

function onClickSubAction(type: string, value?: any) {
  emit('onClickSubAction', type, value);
}

function updateFillColor(stateList: Record<string, boolean>) {
  const newFillOutside = { ...fillOutside.value };
  Object.keys(newFillOutside).map((v) => {
    newFillOutside[v] = stateList[v] ? '#333333' : '#212121';
  });
  fillOutside.value = newFillOutside;
  const newFillInside = { ...fillInside.value };
  Object.keys(newFillInside).map((v) => {
    newFillInside[v] = stateList[v] ? '#333333' : '#212121';
  });
  fillInside.value = newFillInside;
}

function insideMouseleave() {
  updateFillColor(stateList.value);
  isHoverInside.value = false;
}

function insideMouseover(property: string) {
  fillInside.value = {
    ...fillInside.value,
    [property]: '#333333',
  };
  isHoverInside.value = true;
}

function outsideMouseleave() {
  updateFillColor(stateList.value);
  isHoverOutside.value = false;
}

function outsideMouseover(property: string) {
  fillOutside.value = {
    ...fillOutside.value,
    [property]: '#333333',
  };
  isHoverOutside.value = true;
}

function insideWithValue(pos: keyof PaddingType) {
  return props.padding?.[pos];
}

function outsideWithValue(pos: keyof PaddingType) {
  return props.margin?.[pos]?.toString();
}

function onSelectSpacing(property: string, isShow: boolean) {
  isOpenSelectSpacing.value = isShow;
  const [kind] = property.split('-');
  if (kind === 'margin') {
    isHoverOutside.value = isShow;
  } else {
    isHoverInside.value = isShow;
  }
}

function handleChangeInsideDiffValue(property: keyof SpacingOption, diff: string) {
  insidePropertyDiffList.value = {
    ...insidePropertyDiffList.value,
    [property]: diff,
  };
}

function handleChangeOutDiffValue(property: keyof SpacingOption, diff: string) {
  outsidePropertyDiffList.value = {
    ...outsidePropertyDiffList.value,
    [property]: diff,
  };
}

function changeStateListOnFocus(pos: string) {
  stateList.value = {
    [pos]: true,
  };
  updateFillColor(stateList.value);
}
</script>

<template>
  <div ref="target" class="box overflow-hidden rounded-xl">
    <div data-test="editor-control-spacing-outside" class="outside h-full w-full">
      <div v-if="link && isHoverOutside">
        <div class="outside-top-left"></div>
        <div class="outside-bottom-left"></div>
        <div class="outside-top-right"></div>
        <div class="outside-bottom-right"></div>
      </div>
      <svg
        style="grid-area: 1 / 1 / -1 / -1"
        class="overflow-hidden"
        :class="isOnChangingMargin ? '[&>g]:!fill-[#333333]' : ''"
        xmlns="http://www.w3.org/2000/svg"
        width="248"
        height="220"
        viewBox="0 0 248 220"
        fill="none">
        <OutsideTriangle
          v-for="(item, index) in buildOutsideProperty"
          :id="id"
          :key="index"
          :item="item"
          :fill-outside="fillOutside[item.property]"
          :is-open-select-spacing="isOpenSelectSpacing"
          :options="options"
          :value="outsideWithValue(item.pos)"
          @click="changeStateListOnFocus"
          @outside-mouseover="outsideMouseover"
          @change-diff-value="handleChangeOutDiffValue"
          @outside-mouseleave="outsideMouseleave" />
      </svg>
      <Outside
        v-for="(item, index) in buildOutsideProperty"
        :id="id"
        :key="index"
        :options="options"
        :item="item"
        :is-focus="stateList[item.property]"
        :is-open-select-spacing="isOpenSelectSpacing"
        :diff-value="outsidePropertyDiffList[item.property]"
        :value="outsideWithValue(item.pos)"
        :on-control-change="onControlChange"
        :on-select-spacing="onSelectSpacing"
        :control-change-prop="controlChangeProp"
        :on-click-sub-action="onClickSubAction"
        @outside-mouseover="outsideMouseover"
        @outside-mouseleave="outsideMouseleave"></Outside>
    </div>
    <div
      data-test="editor-control-spacing-inside"
      class="inside rounded-medium border-dark-100 relative overflow-hidden border">
      <div v-if="link && isHoverInside">
        <div class="inside-top-left"></div>
        <div class="inside-bottom-left"></div>
        <div class="inside-top-right"></div>
        <div class="inside-bottom-right"></div>
      </div>
      <button
        data-test="editor-control-spacing-synchronize"
        class="bg-dark-500 hover:border-dark-500 transform-center hover:bg-dark-200 flex h-[32px] w-[38px] items-center justify-center rounded-xl border border-transparent"
        @click.stop="changeLink"
        @mouseover="isHoverInside = true"
        @mouseleave="isHoverInside = false">
        <g-base-icon
          v-if="!link"
          name="spacing-un-link"
          width="16px"
          height="16px"
          view-box="0 0 16 16"
          class="text-light-400">
        </g-base-icon>
        <g-base-icon
          v-else
          name="spacing-link"
          width="16px"
          height="16px"
          view-box="0 0 16 16"
          class="text-light-400"></g-base-icon>
      </button>
      <svg
        class="overflow-hidden"
        :class="isOnChangingPadding ? '[&>g]:!fill-[#333333]' : ''"
        style="grid-area: 1 / 1 / -1 / -1"
        width="144"
        height="132"
        viewBox="0 0 144 132"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <InsideTriangle
          v-for="(item, index) in buildInsideProperty"
          :id="id"
          :key="index"
          :is-open-select-spacing="isOpenSelectSpacing"
          :fill-inside="fillInside[item.property]"
          :item="item"
          :options="options"
          :value="insideWithValue(item.pos)"
          @click="changeStateListOnFocus"
          @inside-mouseleave="insideMouseleave"
          @inside-mouseover="insideMouseover"
          @change-diff-value="handleChangeInsideDiffValue" />
      </svg>
      <Inside
        v-for="(item, index) in buildInsideProperty"
        :id="id"
        :key="index"
        :options="options"
        :item="item"
        :is-focus="stateList[item.property]"
        :is-open-select-spacing="isOpenSelectSpacing"
        :diff-value="insidePropertyDiffList[item.property]"
        :value="insideWithValue(item.pos)"
        :on-control-change="onControlChange"
        :on-select-spacing="onSelectSpacing"
        :control-change-prop="controlChangeProp"
        :on-click-sub-action="onClickSubAction"
        @inside-mouseleave="insideMouseleave"
        @inside-mouseover="insideMouseover" />
    </div>
  </div>
</template>

<style scoped lang="scss">
.box {
  position: relative;
  display: grid;
  height: 220px;
  grid-template-columns: 48px 4px 36px 1fr 36px 4px 36px;
  grid-template-rows: 40px 4px 16px 1fr 21px 7px 31px;
  outline-style: none;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
}

.outside-top-left {
  width: 70px;
  transform: rotate(40deg);
  transform-origin: top left;
  border-top: 1px solid #424242;
  position: absolute;
  top: 0px;
  left: 0px;
  box-sizing: border-box;
  z-index: 1;
}
.outside-bottom-left {
  width: 70px;
  transform: rotate(-39.89deg);
  transform-origin: bottom left;
  border-top: 1px solid #424242;
  position: absolute;
  bottom: 0px;
  left: 0px;
  box-sizing: border-box;
  z-index: 1;
}
.outside-top-right {
  width: 70px;
  transform: rotate(-40deg);
  transform-origin: top right;
  border-top: 1px solid #424242;
  position: absolute;
  top: 0px;
  right: 0px;
  box-sizing: border-box;
  z-index: 1;
}
.outside-bottom-right {
  width: 70px;
  transform: rotate(40deg);
  transform-origin: bottom right;
  border-top: 1px solid #424242;
  position: absolute;
  bottom: 0px;
  right: 0px;
  box-sizing: border-box;
  z-index: 1;
}

.inside {
  grid-area: 3 / 3 / span 3 / span 3;
  display: grid;
  grid-template-columns: 48px 48px 48px;
  grid-template-rows: 44px minmax(16px, 1fr) 44px;
  justify-items: center;
  width: 144px;
  height: 132px;
}
.inside-top-left {
  @extend .outside-top-left;
  width: 80px;
  z-index: 0;
  transform: rotate(42deg);
}
.inside-bottom-left {
  @extend .outside-bottom-left;
  width: 80px;
  z-index: 0;
  transform: rotate(-41.9deg);
  left: 1.5px;
}
.inside-top-right {
  @extend .outside-top-right;
  width: 80px;
  z-index: 0;
  transform: rotate(-42deg);
}
.inside-bottom-right {
  @extend .outside-bottom-right;
  width: 80px;
  z-index: 0;
  transform: rotate(41.9deg);
  right: 1.5px;
}
.outside {
  grid-area: 1 / 1 / -1 / -1;
  display: grid;
  grid-template-columns: 52px 1fr 52px;
  grid-template-rows: 44px minmax(16px, 1fr) 44px;
  justify-items: center;
}

.transform-center {
  @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 transform;
}
</style>
