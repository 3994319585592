export const gridToArrayRegex = /[^\s()]+(?:\([^\s()]+(?:\([^()]+\))?(?:, *[^\s()]+(?:\([^()]+\))?)*\))?/g; // Regex convert css grid to array

export const isColor = (color: string) => {
  return (
    color?.startsWith('#') ||
    color?.startsWith('rgb') ||
    color?.startsWith('hsl') ||
    color?.startsWith('transparent') ||
    color?.startsWith('linear-gradient')
  );
};
export const isEmail = (email: string) => {
  const regexEmail =
    // eslint-disable-next-line no-useless-escape
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;
  if (email && regexEmail.test(email)) return true;
  return false;
};

export const isValidHexWithoutPrefix = (value: string): boolean => {
  const hexWithoutPrefixRegexp = /^[0-9A-F]{6}$/i;
  return hexWithoutPrefixRegexp.test(value);
};

export const isValidColorFormat = (value: string): boolean => {
  const rgbaRegexp = /^rgb(a?)[(]\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*,\s*([\d.]+\s*%?)\s*(?:,\s*([\d.]+)\s*)?[)]$/;
  const hexRegexp = /^#[0-9A-F]{6}$/i;
  return rgbaRegexp.test(value) || hexRegexp.test(value);
};

export const getHexColorThreeDigit = (value: string): string | null => {
  const hexWithoutPrefixRegexp = /^(#)?[0-9A-F]{3}$/i;
  if (hexWithoutPrefixRegexp.test(value)) {
    return value.startsWith('#') ? value : `#${value}`;
  }
  return null;
};
