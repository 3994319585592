<script setup lang="ts">
import parseUnit from '@gem/control/src/helpers/parseUnit';
import { computed } from 'vue';
import type { ScreenType } from '../../types';
import InputUnit from '../InputUnit.vue';
import type { OptionKeyword, SizeConfig } from './type';

type Props = {
  id: string;
  value?: string;
  sizeConfig?: SizeConfig;
  displayOptions?: OptionKeyword[];
  units?: string[];
  currentScreen?: ScreenType;
  globalStyleContainerWidth?: string;
  placeholder?: string;
  focus?: boolean;
};

type OptionItem = {
  title: string;
  value: string;
  keyword: OptionKeyword;
  valueDisplay?: string;
  showValue?: boolean;
};

const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'onChange', controlId: string, value?: string): void;
  (e: 'change', controlId: string, value?: string): void;
  (e: 'changeScreen', screenId: ScreenType): void;
}>();
const globalStyleContainerWidth = computed<string>(() => props.globalStyleContainerWidth || '');

const displayValue = computed(() => {
  switch (props.value) {
    case 'default':
      return globalStyleContainerWidth.value as string;
    case 'fit-content':
      return 'Auto';
    default:
      return props.value;
  }
});

const options: OptionItem[] = [
  {
    title: 'Fit content',
    value: 'Auto',
    keyword: 'auto',
    showValue: true,
  },
  {
    title: 'Fit content',
    value: 'fit-content',
    valueDisplay: 'Auto',
    keyword: 'fit-content',
    showValue: true,
  },
  {
    title: 'Full',
    value: '100%',
    keyword: 'full',
    showValue: true,
  },
  {
    title: 'Fit screen',
    value: '100vh',
    keyword: 'fit-screen',
    showValue: true,
  },
  {
    title: 'Default',
    value: 'default',
    valueDisplay: globalStyleContainerWidth.value,
    keyword: 'default',
    showValue: true,
  },
  {
    title: 'Original size',
    value: 'auto',
    valueDisplay: 'Auto',
    keyword: 'original',
    showValue: true,
  },
  {
    title: 'Small',
    value: props.sizeConfig?.small || '100px',
    keyword: 'small',
    showValue: true,
  },
  {
    title: 'Medium',
    value: props.sizeConfig?.medium || '200px',
    keyword: 'medium',
    showValue: true,
  },
  {
    title: 'Large',
    value: props.sizeConfig?.large || '300px',
    keyword: 'large',
    showValue: true,
  },
  {
    title: 'All equal width',
    value: 'globalWidth',
    keyword: 'equal',
  },
  {
    title: 'Full height',
    value: '100%',
    keyword: 'full-height',
    showValue: true,
  },
];

const isDisplayOption = (keyword: OptionKeyword) => {
  return props.displayOptions?.length && props.displayOptions?.includes(keyword);
};

const handleChangeValue = (newValue: string) => {
  emit('change', props.id, newValue);
};

const handleInputChange = (type: 'change' | 'onChange', id: string, newValue?: string) => {
  let currentValue = newValue;
  if (currentValue) {
    currentValue = newValue?.toLowerCase() == 'auto' ? newValue : handleNegativeValue(newValue);
    if (globalStyleContainerWidth.value !== '100%') {
      if (currentValue === globalStyleContainerWidth.value && isDisplayOption('default')) {
        currentValue = 'default';
      }
    } else if (
      currentValue === globalStyleContainerWidth.value &&
      isDisplayOption('default') &&
      !isDisplayOption('full')
    ) {
      currentValue = 'default';
    }
  }

  type === 'change' ? emit('change', id, currentValue) : emit('onChange', id, currentValue);
};

const handleNegativeValue = (newValue?: string) => {
  const numberValue = parseInt(newValue || '');
  if (numberValue >= 0) return newValue;
  else {
    const [_, unit] = parseUnit(newValue || '');
    return `${numberValue * -1}${unit}`;
  }
};
</script>

<template>
  <div class="max-w-input-horizontal w-full">
    <g-popover
      :has-arrow="false"
      :closeable="true"
      :overlay="false"
      overlay-container="#root-modal"
      placement="bottom-end"
      cls="!p-0">
      <template #default="{}">
        <InputUnit
          :id="id"
          :value="displayValue"
          :units="units"
          :empty-on-clear="true"
          :focus="focus"
          :placeholder="placeholder"
          input-class="!w-full"
          :elm-disable-blur-action="['.disable-blur-when-click']"
          :control-change="(id, value) => handleInputChange('change', id, value)"
          @control-on-change="(id, value) => handleInputChange('onChange', id, value)" />
      </template>
      <template v-if="options?.length && displayOptions?.length" #content="{ close }">
        <div class="rounded-12 bg-dark-400 shadow-4dp -mt-4 w-[180px] p-8">
          <template v-for="(opt, index) in options">
            <div
              v-if="isDisplayOption(opt.keyword)"
              :key="index"
              class="disable-blur-when-click text-12 hover:bg-light-100/20 text-light-100 relative flex h-36 w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl pl-40 pr-8"
              @click="
                () => {
                  handleChangeValue(opt.value);
                  close();
                }
              ">
              <span
                v-if="value?.toLocaleLowerCase() === opt.value.toLocaleLowerCase()"
                class="text-light-450 absolute inset-y-0 left-8 flex items-center">
                <GBaseIcon name="status-check" width="20" height="20" />
              </span>
              <p class="flex w-full items-center justify-between truncate">
                <span class="truncate">{{ opt.title }}</span>
                <span v-if="opt.showValue" class="text-text-dark-300">{{ opt.valueDisplay || opt.value }}</span>
              </p>
            </div>
          </template>
        </div>
      </template>
    </g-popover>
  </div>
</template>
