<script setup lang="ts">
import { ref, watch, onMounted } from 'vue';

type Props = {
  min?: number;
  max?: number;
  step?: number;
  modelValue: number;
  fullWidth?: boolean;
  ignoreMax?: boolean;
};

// define component props for the slider component
const props = defineProps<Props>();

// define emits for the slider component
const emit = defineEmits(['update:modelValue']);

// define refs for the slider component
const sliderValue = ref(props.modelValue);
const slider = ref<any>(null);

// function to get the progress of the slider
const getProgress = (value: number, min: number, max: number) => {
  if (max && value && value > max && props.ignoreMax) return 100;
  return ((value - min) / (max - min)) * 100;
};

// function to set the css variable for the progress
const setCSSProgress = (progress: number) => {
  slider.value.style.setProperty('--ProgressPercent', `${progress}%`);
};

const onInputChange = (e: Event) => {
  const sliderValue = (e.target as HTMLInputElement).value;
  emit('update:modelValue', sliderValue);
  changeProgress();
};

onMounted(() => {
  changeProgress();
});

watch(
  () => props.modelValue,
  () => {
    sliderValue.value = props.modelValue;
    if (props.max && props.modelValue > props.max) {
      sliderValue.value = props.max;
    }
    if (props.min && props.modelValue < props.min) {
      sliderValue.value = props.min;
    }

    slider.value.min = props.min;
    slider.value.max = props.max;

    changeProgress();
  },
);

const changeProgress = () => {
  const progress = getProgress(sliderValue.value, slider.value.min, slider.value.max);
  const extraWidth = (100 - progress) / 10;
  setCSSProgress(progress + extraWidth);
};
</script>
<template>
  <div class="custom-slider group/slider" :class="{ 'w-full': fullWidth }">
    <input
      ref="slider"
      :value="sliderValue"
      type="range"
      :min="min"
      :max="max"
      :step="step"
      class="slider"
      :class="{ '!w-full': fullWidth }"
      @input="onInputChange" />
  </div>
</template>
<style scoped lang="postcss">
.custom-slider {
  --trackHeight: 4px;
  --thumbRadius: 1rem;
  display: flex;
}

/* style the input element with type "range" */
.custom-slider input[type='range'] {
  position: relative;
  appearance: none;
  background: none;

  z-index: 0;
  height: 4px;
  width: 71px;
}

/* ::before element to replace the slider track */
.custom-slider input[type='range']::before,
.custom-slider.minmax::before {
  content: '';
  position: absolute;
  width: var(--ProgressPercent, 100%);
  height: 100%;
  background-color: theme('colors.primary.300');
  /* z-index: -1; */
  pointer-events: none;
  border-radius: 0.25rem;
  z-index: 1;
}

/* `::-webkit-slider-runnable-track` targets the track (background) of a range slider in chrome and safari browsers. */
.custom-slider input[type='range']::-webkit-slider-runnable-track {
  appearance: none;
  background-color: transparent;
  height: var(--trackHeight);
}

/* `::-moz-range-track` targets the track (background) of a range slider in Mozilla Firefox. */
.custom-slider input[type='range']::-moz-range-track {
  appearance: none;
  height: var(--trackHeight);
  border-radius: 999px;
  background: repeating-linear-gradient(
    90deg,
    transparent,
    transparent 3px,
    theme('colors.dark.250') 0px,
    theme('colors.dark.250') 6px
  );
}

.custom-slider input[type='range']::-webkit-slider-thumb {
  position: relative;
  width: var(--thumbRadius);
  height: var(--thumbRadius);
  margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
  background-color: theme('colors.primary.300');
  border-radius: 999px;
  pointer-events: all;
  cursor: pointer;
  z-index: 1;
  &:hover {
    border: 1px solid #151515;
    outline: 2px solid #7190ff;
  }
}

.custom-slider input[type='range']::-moz-range-thumb {
  appearance: none;
  -moz-appearance: none;
  position: relative;
  width: var(--thumbRadius);
  height: var(--thumbRadius);
  margin-top: calc((var(--trackHeight) - var(--thumbRadius)) / 2);
  background-color: theme('colors.primary.300');
  border-radius: 999px;
  pointer-events: all;
  cursor: pointer;
  z-index: 1;
  &:hover {
    border: 1px solid #151515;
    outline: 2px solid #7190ff;
  }
}

.custom-slider input[type='range']::-moz-range-progress {
  background-color: theme('colors.primary.300');
  border-radius: 10px;
  height: var(--trackHeight);
}

.custom-slider input[type='range']::after,
.custom-slider.minmax::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 0.25rem;
  background-image: linear-gradient(to right, #424242, #424242 50%, transparent 50%, transparent 100%);
  background-size: 0.25rem 0.25rem;
  border-radius: 0.25rem;
  border-right: 0.25rem #424242 solid;
}
</style>
