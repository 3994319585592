import type { FontFamilyType, TypographyV2Attrs, TypographyV2Props } from '../../types';

export type TypographyType =
  | 'heading-1'
  | 'heading-2'
  | 'heading-3'
  | 'subheading-1'
  | 'subheading-2'
  | 'subheading-3'
  | 'paragraph-1'
  | 'paragraph-2'
  | 'paragraph-3';

export type TypographyProps = {
  fontSize?: string;
  fontWeight?: string | number;
  fontStyle?: string;
  fontFamily?: string;
  lineHeight?: string;
  letterSpacing?: string;
};

export type TypographyValue = {
  type?: TypographyType;
  custom?: TypographyV2Props;
  attrs?: TypographyV2Attrs;
};

export type FontItem = {
  family: string;
  variants: string[];
  subsets: string[];
  type?: FontFamilyType;
};

export type FontName = 'body' | 'heading' | 'code';

export type globalStyleFontProps = Partial<Record<FontName, FontItem>>;

export function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const ENABLE_TEXT_SHADOW_ELEMENT = [
  'Heading',
  'Text',
  'ProductTitle',
  'ProductPrice',
  'ProductVendor',
  'Tabs',
  'Accordion',
  'ProductTag',
  'Countdown',
  'StockCounter',
  'ArticleContent',
  'ArticleAuthor',
  'ArticleDate',
  'ArticleTag',
  'ArticleExcerpt',
  'ArticleCategory',
  'ArticleTitle',
  'ProductBadge',
];
