<script lang="ts" setup>
import { useInfiniteScroll } from '@vueuse/core';
import { ref, watch, reactive } from 'vue';
import PickLinkNoResult from '../../assets/PickLinkNoResult.png';
import type { TabType } from '../../type/pickLink';
import { isEmail } from '../../helpers/regex';

type PickLinkList = {
  handle?: string;
  title?: string;
  name?: string;
  id?: string;
  isThemeSection?: boolean;
  index?: number;
};
type Props = {
  list?: PickLinkList[];
  loading?: boolean;
  type: TabType;
  shopDomain?: string;
  customUrl?: string;
  link?: string;
  isInstant?: boolean;
  inputSearchComponent?: Object;
  isShowBanner?: boolean;
};
const props = defineProps<Props>();

const emit = defineEmits<{
  (e: 'showMore'): void;
  (e: 'setCustomUrl', value: string, isEmail?: boolean | undefined): void;
  (e: 'create-popup'): void;
  (e: 'setCustomTitle', value: string): void;
  (e: 'onSearch', value: string): void;
  (e: 'onHandleDisable', value: boolean): void;
  (e: 'close-banner'): void;
}>();

const SHOPIFY_PAGE_DATA: {
  [key: string]: { title: string; uri: string; pathName: string };
} = {
  collections: {
    title: 'Collection',
    uri: `https://${props.shopDomain}/collections`,
    pathName: '/collections',
  },
  products: {
    title: 'Product',
    uri: `https://${props.shopDomain}/products`,
    pathName: '/products',
  },
  articles: {
    title: 'Articles',
    uri: `https://${props.shopDomain}/blogs`,
    pathName: '/blogs',
  },
  pages: {
    title: 'Page',
    uri: `https://${props.shopDomain}/pages`,
    pathName: '/pages',
  },
};

const INSTANT_PAGE_DATA: {
  [key: string]: { title: string; uri: string; pathName: string };
} = {
  pages: {
    title: 'Page',
    uri: `https://${props.shopDomain}/`,
    pathName: '/',
  },
};

const getPhoneDefaultUrl = () => {
  if (props.customUrl?.includes('tel:')) {
    const phone = props.customUrl?.replace('tel:', '');

    return phone;
  }
  return '';
};

const getMailDefaultUrl = () => {
  if (props.customUrl?.includes('mailto:')) {
    const mails = props.customUrl?.replace('mailto:', '');
    const splitMails = mails?.split('?');
    return {
      email: splitMails?.[0],
      subject: splitMails?.[1]?.split('&')[0]?.replace('subject=', '') || '',
      message: splitMails?.[1]?.split('&')[1]?.replace('body=', '') || '',
    };
  }
  return { email: '', subject: '', message: '' };
};
const openTutorial = (url: string) => {
  window.open(url, '_blank');
};

const getDefaultSelectedItem = () => {
  if (
    props.type === 'articles' ||
    props.type === 'pages' ||
    props.type === 'collections' ||
    props.type === 'products'
  ) {
    const pageData = props.isInstant ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const basePathName = pageData[props.type]?.pathName || '';
    if (props.link?.includes(basePathName)) {
      const handle = props.link?.replace(`${basePathName}/`, '') || '';
      const id = props.list?.find((item) => item.handle === handle)?.id || (handle === 'all' ? 'all-product' : '');
      return { id, handle };
    }
  } else if (props.link?.startsWith('#') && (props.type === 'scrollToList' || props.type === 'popup')) {
    return { id: props.link?.replace('#', '') || '', handle: '' };
  }
  return { id: '', handle: '' };
};

const searchVal = ref('');
const searchFocus = ref(false);
const phoneUrl = ref<string>(getPhoneDefaultUrl());

const selectedItem = ref<{ id?: string; handle?: string }>(getDefaultSelectedItem());
const emailUrl = reactive<{ email?: string; subject?: string; message?: string }>(getMailDefaultUrl());
const infinityScroll = ref<HTMLElement | null>(null);

// code bẩn sửa sau
watch(emailUrl, (value) => {
  if (value.email) {
    const emailContent =
      'mailto:' +
      (value.email && value.email) +
      (value.subject && '?subject=' + value.subject) +
      (value.message && '&body=' + value.message);
    emit('setCustomUrl', emailContent, isEmail(value.email));
  }
});

watch(phoneUrl, (value) => {
  emit('setCustomUrl', 'tel:' + value);
});

watch(
  () => props.link,
  (value) => {
    if (props.type !== 'scrollToList' && props.type !== 'popup') {
      const pageData = props.isInstant ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
      const basePathName = pageData[props.type]?.pathName || '';
      if (value !== `${basePathName}/${selectedItem.value?.handle}`) {
        selectedItem.value = { id: '', handle: '' };
      }
    } else {
      if (value !== `#${selectedItem.value?.id}`) {
        selectedItem.value = { id: '', handle: '' };
      }
    }
  },
);

useInfiniteScroll(
  infinityScroll,
  () => {
    // load more
    emit('showMore');
  },
  { distance: 100 },
);

const onSearch = (value: string) => {
  infinityScrollToTop();
  searchVal.value = value;
  emit('onSearch', value);
};

const clear = () => {
  searchVal.value = '';
  infinityScrollToTop();
  emit('onSearch', searchVal.value || '');
  searchFocus.value = true;
};

const infinityScrollToTop = () => {
  if (infinityScroll.value) {
    infinityScroll.value.scrollTop = 0;
  }
};

const onClick = (type: Props['type'], item: PickLinkList) => {
  if (selectedItem.value?.id === item.id) {
    emit('setCustomUrl', '');
    selectedItem.value = {
      id: '',
      handle: '',
    };

    return;
  }
  selectedItem.value = item;
  emit(
    'setCustomTitle',
    (item.title ?? '')
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;')
      .replace(/'/g, '&#039;'),
  );
  if (type !== 'scrollToList' && type !== 'popup') {
    const pageData = props.isInstant ? INSTANT_PAGE_DATA : SHOPIFY_PAGE_DATA;
    const baseUrl = pageData[type]?.uri || '';
    emit('setCustomUrl', `${baseUrl}/${item.handle}` ?? '#');
  } else {
    emit('setCustomUrl', '#' + item.id ?? '#');
  }
};

const onChange = (value: string, name: 'email' | 'subject' | 'message') => {
  emailUrl[name] = value;
};
const onChangePhone = (value: string) => {
  phoneUrl.value = value;
};

const closeScrollToGuideline = () => {
  emit('close-banner');
};
const createNewPopup = () => {
  emit('create-popup');
};
</script>
<template>
  <div class="bg-light-400 h-full pt-0">
    <div class="flex h-full flex-col">
      <div
        v-if="
          type === 'pages' ||
          type === 'collections' ||
          type === 'products' ||
          type === 'articles' ||
          type === 'scrollToList' ||
          (type === 'popup' &&
            (searchVal.trim().length > 0 ||
              searchFocus ||
              (searchVal.trim().length == 0 && props.list && props.list.length > 0)))
        "
        class="w-full">
        <div class="my-16 flex items-center px-16">
          <component
            :is="inputSearchComponent"
            type="text"
            should-focus
            :placeholder="type === 'scrollToList' ? 'Search section...' : 'Search...'"
            :query-value="searchVal"
            icon-search-style="!text-text-light-300"
            class="w-full rounded-xl"
            input-style="normalLight"
            @search="onSearch"
            @focus="searchFocus = true"
            @blur="searchFocus = false"
            @clear="clear" />
        </div>
      </div>
      <div
        v-if="
          (type === 'pages' || type === 'collections' || type === 'products' || type === 'articles') &&
          props.list &&
          props.list.length > 0
        "
        class="flex h-[calc(100%_-_72px)] max-h-[480px] flex-col overflow-hidden">
        <perfect-scrollbar ref="infinityScroll" class="h-full">
          <div
            v-if="type === 'collections'"
            :data-test="`modal-control-page-item-collections`"
            class="group/radio relative mx-16 mb-16 flex min-h-[56px] cursor-pointer items-center justify-between rounded-[12px] bg-white px-16"
            @click="
              onClick('collections', {
                id: 'all-product',
                title: 'All Products',
                handle: 'all',
              })
            ">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 3C1.5 2.17157 2.17157 1.5 3 1.5H5.85C6.67843 1.5 7.35 2.17157 7.35 3V5.85C7.35 6.67843 6.67843 7.35 5.85 7.35H3C2.17157 7.35 1.5 6.67843 1.5 5.85V3ZM3 2.5H5.85C6.12614 2.5 6.35 2.72386 6.35 3V5.85C6.35 6.12614 6.12614 6.35 5.85 6.35H3C2.72386 6.35 2.5 6.12614 2.5 5.85V3C2.5 2.72386 2.72386 2.5 3 2.5Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 10.1501C1.5 9.32169 2.17157 8.65012 3 8.65012H5.85C6.67843 8.65012 7.35 9.32169 7.35 10.1501V13.0001C7.35 13.8285 6.67843 14.5001 5.85 14.5001H3C2.17157 14.5001 1.5 13.8285 1.5 13.0001V10.1501ZM3 9.65012H5.85C6.12614 9.65012 6.35 9.87397 6.35 10.1501V13.0001C6.35 13.2763 6.12614 13.5001 5.85 13.5001H3C2.72386 13.5001 2.5 13.2763 2.5 13.0001V10.1501C2.5 9.87397 2.72386 9.65012 3 9.65012Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1501 1.5C9.32167 1.5 8.6501 2.17157 8.6501 3V5.85C8.6501 6.67843 9.32167 7.35 10.1501 7.35H13.0001C13.8285 7.35 14.5001 6.67843 14.5001 5.85V3C14.5001 2.17157 13.8285 1.5 13.0001 1.5H10.1501ZM13.0001 2.5H10.1501C9.87396 2.5 9.6501 2.72386 9.6501 3V5.85C9.6501 6.12614 9.87396 6.35 10.1501 6.35H13.0001C13.2762 6.35 13.5001 6.12614 13.5001 5.85V3C13.5001 2.72386 13.2762 2.5 13.0001 2.5Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.6501 10.1501C8.6501 9.32169 9.32167 8.65012 10.1501 8.65012H13.0001C13.8285 8.65012 14.5001 9.32169 14.5001 10.1501V13.0001C14.5001 13.8285 13.8285 14.5001 13.0001 14.5001H10.1501C9.32167 14.5001 8.6501 13.8285 8.6501 13.0001V10.1501ZM10.1501 9.65012H13.0001C13.2762 9.65012 13.5001 9.87397 13.5001 10.1501V13.0001C13.5001 13.2763 13.2762 13.5001 13.0001 13.5001H10.1501C9.87396 13.5001 9.6501 13.2763 9.6501 13.0001V10.1501C9.6501 9.87397 9.87396 9.65012 10.1501 9.65012Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-dark-400 max-w-[calc(100%_-_32px)] truncate font-medium">All Products</p>
            </div>
            <svg
              v-if="selectedItem?.id !== 'all-product'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
            <div
              class="group-hover/radio:bg-overlay-grey pointer-events-none absolute right-[12px] top-1/2 h-28 w-28 -translate-y-1/2 rounded-[24px]"></div>
          </div>
          <div
            v-for="(item, idx) in props.list"
            :key="idx"
            :data-test="`modal-control-page-item-${type}`"
            class="group/radio relative mx-16 mb-16 flex min-h-[56px] cursor-pointer items-center justify-between rounded-[12px] bg-white px-16"
            @click="onClick(type, item)">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg
                  v-if="type === 'articles'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M9.10001 8.50012C8.82386 8.50012 8.60001 8.72398 8.60001 9.00012C8.60001 9.27626 8.82386 9.50012 9.10001 9.50012H11.6C11.8761 9.50012 12.1 9.27626 12.1 9.00012C12.1 8.72398 11.8761 8.50012 11.6 8.50012H9.10001Z"
                    fill="#676767" />
                  <path
                    d="M8.60001 10.7501C8.60001 10.474 8.82386 10.2501 9.10001 10.2501H10.6C10.8761 10.2501 11.1 10.474 11.1 10.7501C11.1 11.0263 10.8761 11.2501 10.6 11.2501H9.10001C8.82386 11.2501 8.60001 11.0263 8.60001 10.7501Z"
                    fill="#676767" />
                  <path
                    d="M4.2 8.50012C3.92385 8.50012 3.7 8.72398 3.7 9.00012C3.7 9.27626 3.92385 9.50012 4.2 9.50012H6.7C6.97614 9.50012 7.2 9.27626 7.2 9.00012C7.2 8.72398 6.97614 8.50012 6.7 8.50012H4.2Z"
                    fill="#676767" />
                  <path
                    d="M3.7 10.7501C3.7 10.474 3.92385 10.2501 4.2 10.2501H6.7C6.97614 10.2501 7.2 10.474 7.2 10.7501C7.2 11.0263 6.97614 11.2501 6.7 11.2501H4.2C3.92385 11.2501 3.7 11.0263 3.7 10.7501Z"
                    fill="#676767" />
                  <path
                    d="M4.2 12.0001C3.92385 12.0001 3.7 12.224 3.7 12.5001C3.7 12.7763 3.92385 13.0001 4.2 13.0001H6.7C6.97614 13.0001 7.2 12.7763 7.2 12.5001C7.2 12.224 6.97614 12.0001 6.7 12.0001H4.2Z"
                    fill="#676767" />
                  <path
                    d="M8.60001 12.5001C8.60001 12.224 8.82386 12.0001 9.10001 12.0001H11.6C11.8761 12.0001 12.1 12.224 12.1 12.5001C12.1 12.7763 11.8761 13.0001 11.6 13.0001H9.10001C8.82386 13.0001 8.60001 12.7763 8.60001 12.5001Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M4.5 3.00092C3.94772 3.00092 3.5 3.44863 3.5 4.00092V6.50092C3.5 7.0532 3.94772 7.50092 4.5 7.50092H11.5C12.0523 7.50092 12.5 7.0532 12.5 6.50092V4.00092C12.5 3.44863 12.0523 3.00092 11.5 3.00092H4.5ZM4.5 4.00092H11.5V6.50092H4.5V4.00092Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3 1C2.17157 1 1.5 1.67157 1.5 2.5V13.5C1.5 14.3284 2.17157 15 3 15H13C13.8284 15 14.5 14.3284 14.5 13.5V2.5C14.5 1.67157 13.8284 1 13 1H3ZM2.5 2.5C2.5 2.22386 2.72386 2 3 2H13C13.2761 2 13.5 2.22386 13.5 2.5V13.5C13.5 13.7761 13.2761 14 13 14H3C2.72386 14 2.5 13.7761 2.5 13.5V2.5Z"
                    fill="#676767" />
                </svg>
                <svg
                  v-if="type === 'collections'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 3C1.5 2.17157 2.17157 1.5 3 1.5H5.85C6.67843 1.5 7.35 2.17157 7.35 3V5.85C7.35 6.67843 6.67843 7.35 5.85 7.35H3C2.17157 7.35 1.5 6.67843 1.5 5.85V3ZM3 2.5H5.85C6.12614 2.5 6.35 2.72386 6.35 3V5.85C6.35 6.12614 6.12614 6.35 5.85 6.35H3C2.72386 6.35 2.5 6.12614 2.5 5.85V3C2.5 2.72386 2.72386 2.5 3 2.5Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.5 10.1501C1.5 9.32169 2.17157 8.65012 3 8.65012H5.85C6.67843 8.65012 7.35 9.32169 7.35 10.1501V13.0001C7.35 13.8285 6.67843 14.5001 5.85 14.5001H3C2.17157 14.5001 1.5 13.8285 1.5 13.0001V10.1501ZM3 9.65012H5.85C6.12614 9.65012 6.35 9.87397 6.35 10.1501V13.0001C6.35 13.2763 6.12614 13.5001 5.85 13.5001H3C2.72386 13.5001 2.5 13.2763 2.5 13.0001V10.1501C2.5 9.87397 2.72386 9.65012 3 9.65012Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.1501 1.5C9.32167 1.5 8.6501 2.17157 8.6501 3V5.85C8.6501 6.67843 9.32167 7.35 10.1501 7.35H13.0001C13.8285 7.35 14.5001 6.67843 14.5001 5.85V3C14.5001 2.17157 13.8285 1.5 13.0001 1.5H10.1501ZM13.0001 2.5H10.1501C9.87396 2.5 9.6501 2.72386 9.6501 3V5.85C9.6501 6.12614 9.87396 6.35 10.1501 6.35H13.0001C13.2762 6.35 13.5001 6.12614 13.5001 5.85V3C13.5001 2.72386 13.2762 2.5 13.0001 2.5Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M8.6501 10.1501C8.6501 9.32169 9.32167 8.65012 10.1501 8.65012H13.0001C13.8285 8.65012 14.5001 9.32169 14.5001 10.1501V13.0001C14.5001 13.8285 13.8285 14.5001 13.0001 14.5001H10.1501C9.32167 14.5001 8.6501 13.8285 8.6501 13.0001V10.1501ZM10.1501 9.65012H13.0001C13.2762 9.65012 13.5001 9.87397 13.5001 10.1501V13.0001C13.5001 13.2763 13.2762 13.5001 13.0001 13.5001H10.1501C9.87396 13.5001 9.6501 13.2763 9.6501 13.0001V10.1501C9.6501 9.87397 9.87396 9.65012 10.1501 9.65012Z"
                    fill="#676767" />
                </svg>
                <svg
                  v-if="type === 'pages'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.5 2.16667C2.5 1.52233 3.02233 1 3.66667 1H9.90754C10.2367 1 10.5505 1.13904 10.7717 1.38284L13.1975 4.05719C13.3922 4.27182 13.5 4.55123 13.5 4.84101V13.8333C13.5 14.4777 12.9777 15 12.3333 15H3.66667C3.02233 15 2.5 14.4777 2.5 13.8333V2.16667ZM3.66667 2C3.57462 2 3.5 2.07462 3.5 2.16667V11H12.5V5.2H9.97209C9.69595 5.2 9.47209 4.97614 9.47209 4.7V2H3.66667ZM10.4721 2.54099V4.2H11.9769L10.4721 2.54099ZM3.5 13.8333V12H12.5V13.8333C12.5 13.9254 12.4254 14 12.3333 14H3.66667C3.57462 14 3.5 13.9254 3.5 13.8333Z"
                    fill="#676767" />
                </svg>
                <svg
                  v-if="type === 'products'"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.5832 5.30411C5.58299 5.02815 5.35922 4.8045 5.0832 4.8045C4.80706 4.8045 4.5832 5.02836 4.5832 5.3045H5.0832C4.5832 5.3045 4.5832 5.30427 4.5832 5.3045L4.58321 5.30551L4.58321 5.30683L4.58323 5.31039L4.58334 5.32112C4.58347 5.32985 4.5837 5.34172 4.58413 5.35652C4.58499 5.38609 4.58666 5.42745 4.58987 5.47892C4.5963 5.58171 4.60896 5.72574 4.63401 5.89749C4.68373 6.23842 4.78409 6.70159 4.98971 7.17158C5.19544 7.64181 5.51452 8.13671 6.01065 8.51471C6.51337 8.89773 7.16831 9.13783 7.99987 9.13783C8.82856 9.13783 9.48017 8.90432 9.9797 8.52901C10.4732 8.15822 10.788 7.67212 10.989 7.20982C11.1898 6.74815 11.2846 6.29344 11.3301 5.95905C11.3531 5.79055 11.3639 5.6492 11.3691 5.54826C11.3716 5.4977 11.3728 5.45705 11.3733 5.42792C11.3735 5.41335 11.3736 5.40164 11.3736 5.393L11.3736 5.38235L11.3735 5.37879L11.3735 5.37746C11.3735 5.37722 11.3735 5.37643 10.8736 5.38303L11.3735 5.37643C11.3699 5.10031 11.1431 4.87943 10.867 4.88308C10.591 4.88672 10.3702 5.1133 10.3736 5.38924L10.3736 5.39175L10.3734 5.41126C10.3731 5.4298 10.3723 5.45907 10.3704 5.49752C10.3664 5.57458 10.3579 5.68751 10.3393 5.82414C10.3017 6.10003 10.225 6.45902 10.0719 6.81104C9.91914 7.16244 9.69776 7.49004 9.37902 7.72952C9.06629 7.96449 8.62952 8.13783 7.99987 8.13783C7.3731 8.13783 6.93429 7.96126 6.61669 7.71928C6.29252 7.47229 6.06472 7.13385 5.90587 6.77076C5.7469 6.40741 5.66497 6.03725 5.62354 5.75318C5.60302 5.61243 5.5929 5.49605 5.58793 5.41654C5.58545 5.37687 5.58427 5.34661 5.5837 5.32736L5.58324 5.30701L5.5832 5.30411C5.5832 5.30424 5.5832 5.30398 5.5832 5.30411ZM5.0832 5.3045C5.5832 5.3045 5.5832 5.3043 5.5832 5.30411L5.0832 5.3045Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.30176 1C2.51207 1 1.85761 1.61227 1.80509 2.40022L1.07175 13.4002C1.01403 14.266 1.70073 15 2.56843 15H13.4307C14.2984 15 14.9851 14.266 14.9274 13.4002L14.194 2.40022C14.1415 1.61227 13.4871 1 12.6974 1H3.30176ZM2.80287 2.46674C2.82038 2.20409 3.03853 2 3.30176 2H12.6974C12.9606 2 13.1787 2.20409 13.1963 2.46674L13.9296 13.4667C13.9488 13.7553 13.7199 14 13.4307 14H2.56843C2.27919 14 2.0503 13.7553 2.06953 13.4667L2.80287 2.46674Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-dark-400 max-w-[calc(100%_-_32px)] truncate font-medium">{{ item.title }}</p>
            </div>
            <svg
              v-if="selectedItem?.id !== item.id"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
            <div
              class="group-hover/radio:bg-overlay-grey pointer-events-none absolute right-[12px] top-1/2 h-28 w-28 -translate-y-1/2 rounded-[24px]"></div>
          </div>
          <div v-if="loading">
            <div v-for="i in Array.from(Array(2).keys())" :key="i" class="my-16 px-16">
              <GLoadingPlaceholder width="100%" height="50px"></GLoadingPlaceholder>
            </div>
          </div>
        </perfect-scrollbar>
      </div>

      <div
        v-if="type === 'scrollToList' && props.list && props.list.length > 0"
        class="flex !h-[480px] flex-col overflow-hidden lg:h-[350px]">
        <perfect-scrollbar>
          <div
            v-if="isShowBanner"
            class="bg-light-100 mx-16 mb-16 flex w-[calc(100%_-_32px)] justify-between rounded-[12px] p-16">
            <div class="flex flex-1">
              <div class="text-primary-300 mr-16 h-24 w-24">
                <GBaseIcon name="info24" width="24" height="24" view-box="0 0 24 24" class="text-primary-300" />
              </div>
              <div class="flex flex-col overflow-hidden">
                <p class="text-16 text-dark-400 w-full truncate font-semibold leading-6">Hard to recognize sections?</p>
                <p class="text-14 text-text-light-300 font-regular mt-4 w-full truncate leading-6">
                  You can rename sections for better<br />
                  recognition.
                </p>
              </div>
            </div>
            <img src="./assets/rename-section-panel.svg" class="mx-16 h-[88px] w-[144px]" alt="" />
            <GButtonV2 type="ghost" size="medium" only-icon="close" @click="closeScrollToGuideline"> </GButtonV2>
          </div>
          <div
            v-if="type === 'scrollToList'"
            class="group/radio z-5 relative mx-16 mb-16 flex min-h-[56px] w-[calc(100%-32px)] cursor-pointer items-center justify-between rounded-[12px] bg-white px-16"
            @click="
              onClick('scrollToList', {
                id: 'scroll-to-top',
                title: 'Scroll to top',
              })
            ">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.5 2C2.22386 2 2 2.22386 2 2.5C2 2.77614 2.22386 3 2.5 3H13.5C13.7761 3 14 2.77614 14 2.5C14 2.22386 13.7761 2 13.5 2H2.5ZM8 5C7.81366 5 7.65113 5.09174 7.56513 5.22777L3.64645 9.14645C3.45118 9.34171 3.45118 9.65829 3.64645 9.85355C3.84171 10.0488 4.15829 10.0488 4.35355 9.85355L7.5 6.70711V13.55C7.5 13.7985 7.72386 14 8 14C8.27614 14 8.5 13.7985 8.5 13.55V6.70711L11.6464 9.85355C11.8417 10.0488 12.1583 10.0488 12.3536 9.85355C12.5488 9.65829 12.5488 9.34171 12.3536 9.14645L8.43487 5.22777C8.34887 5.09174 8.18634 5 8 5Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-dark-400 max-w-[calc(100%_-_32px)] truncate font-medium">Top</p>
            </div>

            <svg
              v-if="selectedItem?.id !== 'scroll-to-top'"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
            <div
              class="group-hover/radio:bg-overlay-grey pointer-events-none absolute right-[12px] top-1/2 z-[-1] h-28 w-28 -translate-y-1/2 rounded-[24px]"></div>
          </div>
          <div
            v-for="(item, idx) in props.list"
            :key="idx"
            class="group/radio z-5 relative mx-16 mb-16 flex min-h-[56px] w-[calc(100%-32px)] cursor-pointer items-center justify-between rounded-[12px] bg-white px-16"
            @click="onClick(type, item)">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.3335 1.33301C1.78121 1.33301 1.3335 1.78072 1.3335 2.33301V6.33301C1.3335 6.88529 1.78121 7.33301 2.3335 7.33301H6.3335C6.88578 7.33301 7.3335 6.88529 7.3335 6.33301V2.33301C7.3335 1.78072 6.88578 1.33301 6.3335 1.33301H2.3335ZM6.3335 2.33301H2.3335V6.33301H6.3335V2.33301Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.66683 8.66634C9.11454 8.66634 8.66683 9.11406 8.66683 9.66634V13.6663C8.66683 14.2186 9.11454 14.6663 9.66683 14.6663H13.6668C14.2191 14.6663 14.6668 14.2186 14.6668 13.6663V9.66634C14.6668 9.11406 14.2191 8.66634 13.6668 8.66634H9.66683ZM13.6668 9.66634H9.66683V13.6663H13.6668V9.66634Z"
                    fill="#676767" />
                  <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M1.3335 9.66634C1.3335 9.11406 1.78121 8.66634 2.3335 8.66634H6.3335C6.88578 8.66634 7.3335 9.11406 7.3335 9.66634V13.6663C7.3335 14.2186 6.88578 14.6663 6.3335 14.6663H2.3335C1.78121 14.6663 1.3335 14.2186 1.3335 13.6663V9.66634ZM2.3335 9.66634H6.3335V13.6663H2.3335V9.66634Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-dark-400 max-w-[calc(100%_-_32px)] truncate font-medium">
                {{ item.name }}
                <span class="text-text-light-300 font-regular ml-8">ID: #{{ item.id }}</span>
              </p>
            </div>

            <svg
              v-if="selectedItem?.id !== item.id"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
            <div
              class="group-hover/radio:bg-overlay-grey pointer-events-none absolute right-[12px] top-1/2 z-[-1] h-28 w-28 -translate-y-1/2 rounded-[24px]"></div>
          </div>
        </perfect-scrollbar>
      </div>
      <div
        v-if="type === 'popup' && props.list && props.list.length > 0"
        class="flex !h-[480px] flex-col overflow-hidden lg:h-[350px]">
        <perfect-scrollbar>
          <div
            v-for="(item, idx) in props.list"
            :key="idx"
            class="group/radio z-5 relative mx-16 mb-16 flex min-h-[56px] w-[calc(100%-32px)] cursor-pointer items-center justify-between rounded-[12px] bg-white px-16"
            @click="onClick(type, item)">
            <div class="flex w-[calc(100%-32px)] items-center">
              <div class="mr-16">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="7.5" y="1.5" width="7" height="7" rx="3.5" stroke="#676767" />
                  <path
                    d="M6 2.54167C6 2.24251 5.75749 2 5.45833 2H3.16667C1.97005 2 1 2.97005 1 4.16667V12.8333C1 14.03 1.97005 15 3.16667 15L11.8333 15C13.03 15 14 14.03 14 12.8333V10.5417C14 10.2425 13.7575 10 13.4583 10C13.1592 10 12.9167 10.2425 12.9167 10.5417V12.8333C12.9167 13.4316 12.4316 13.9167 11.8333 13.9167H3.16667C2.56836 13.9167 2.08333 13.4316 2.08333 12.8333V4.16667C2.08333 3.56836 2.56836 3.08333 3.16667 3.08333H5.45833C5.75749 3.08333 6 2.84082 6 2.54167Z"
                    fill="#676767" />
                </svg>
              </div>
              <p class="text-14 text-dark-400 max-w-[calc(100%_-_32px)] truncate font-medium">
                {{ item.name }}
                <span class="text-text-light-300 font-regular ml-8">ID: #{{ item.id }}</span>
              </p>
            </div>

            <svg
              v-if="selectedItem?.id !== item.id"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#676767" stroke-width="1.25" />
            </svg>
            <svg v-else width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <circle cx="10" cy="10" r="4.25" fill="#3C67FF" />
              <rect x="2.625" y="2.625" width="14.75" height="14.75" rx="7.375" stroke="#3C67FF" stroke-width="1.25" />
            </svg>
            <div
              class="group-hover/radio:bg-overlay-grey pointer-events-none absolute right-[12px] top-1/2 z-[-1] h-28 w-28 -translate-y-1/2 rounded-[24px]"></div>
          </div>
        </perfect-scrollbar>
      </div>
      <div
        v-if="
          type === 'popup' && (!props.list || props.list.length === 0) && searchVal.trim().length == 0 && !searchFocus
        "
        class="flex !h-[480px] flex-col items-center justify-center overflow-hidden lg:h-[350px]">
        <div class="mx-auto flex flex-col items-center">
          <img src="./assets/empty-page.svg" alt=" no pick link result" />
          <h3 class="text-dark-400 mt-16 mb-4 font-semibold leading-6">There are no popups on this page</h3>
          <p class="text-12 text-text-light-300 mb-16 leading-5">
            You can create a new popup to select and edit it later.
          </p>
          <GButtonV2
            size="medium"
            type="primary"
            class="text-12 mb-8 flex w-[200px] items-center font-medium leading-5"
            @click="createNewPopup">
            <g-base-icon name="plus-gemui" width="16" height="16" view-box="0 0 16 16" class="mr-8"></g-base-icon>
            Create new popup
          </GButtonV2>
          <GButtonV2
            size="medium"
            type="secondary"
            :light-mode="true"
            class="text-12 mb-8 w-[200px] font-medium leading-5"
            @click="openTutorial(`https://help.gempages.net/articles/v7-popup-element`)">
            Learn more about Popup
          </GButtonV2>
        </div>
      </div>

      <div v-if="type === 'emails'">
        <div class="h-full w-full flex-1 flex-col p-16">
          <div class="mb-16 w-full">
            <p class="text-12 text-dark-400 mb-8 font-medium leading-5">Email Address</p>
            <g-input
              :value="emailUrl.email"
              type="text"
              size="large"
              placeholder="chris@gempages.help"
              prop-name="email"
              class="hover:bg-light-450 hover:rounded-xl focus:bg-white active:bg-white"
              @on-change="onChange" />
          </div>

          <div class="mb-16 w-full">
            <p class="text-12 text-dark-400 mb-8 font-medium leading-5">Subject</p>
            <g-input
              :value="emailUrl.subject"
              type="text"
              size="large"
              placeholder="Need support!"
              prop-name="subject"
              class="hover:bg-light-450 hover:rounded-xl focus:bg-white active:bg-white"
              @on-change="onChange" />
          </div>
          <div class="w-full">
            <p class="text-12 text-dark-400 mb-8 font-medium leading-5">Messages</p>
            <textarea
              v-model="emailUrl.message"
              type="text"
              placeholder="Email Content"
              class="bg-light-100 text-dark-400 text-14 active:border-primary-300 focus:border-primary-300 hover:bg-light-450 h-[40px] min-h-[96px] w-full rounded-xl py-10 px-8 outline-none focus:border focus:bg-white active:border active:bg-white"></textarea>
          </div>
        </div>
      </div>
      <div v-if="type === 'phone'">
        <div class="low:min-h-[365px] h-full min-h-[465px] w-full flex-1 flex-col p-16">
          <div class="mb-16 w-full">
            <p class="text-12 text-dark-400 mb-8 font-medium leading-5">Phone number</p>
            <g-input
              :value="phoneUrl"
              type="text"
              size="large"
              placeholder="E.g +1-708-943-8364"
              class="hover:bg-light-450 hover:rounded-xl focus:bg-white active:bg-white"
              @on-change="onChangePhone" />
          </div>
        </div>
      </div>
      <template
        v-if="
          (!props.list || props.list.length === 0) &&
          type !== 'emails' &&
          type !== 'phone' &&
          type !== 'scrollToList' &&
          type !== 'popup'
        ">
        <div class="mt-36 flex h-full min-h-[300px] items-center justify-center">
          <img :src="PickLinkNoResult" alt=" no pick link result" />
        </div>
      </template>
      <div
        v-if="
          (type === 'scrollToList' || (type === 'popup' && searchVal.trim().length > 0)) &&
          (!props.list || props.list.length === 0)
        "
        class="mt-36 flex h-full min-h-[300px] flex-col items-center justify-center">
        <img src="./assets/no-result.svg" class="h-[80px] w-[80px]" alt=" no pick link result" />
        <div class="text-dark-400 text-16 mt-16 font-semibold leading-6">Insert element name to search</div>
        <p class="text-14 text-text-light-300 text-center leading-6">
          You can rename elements for better <br />
          recognition.
        </p>
      </div>
    </div>
  </div>
</template>
