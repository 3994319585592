<script setup lang="ts">
import { computed, inject, ref, watchEffect } from 'vue';

import { InputUpDownNumber } from '../..';
import InputUnit from '../InputUnit.vue';
import FontFamilySelect from './FontFamilySelect.vue';
import Segment from '../Segment.vue';
import { getResponsiveValueByScreen } from '../../helpers/common';
import type { OptionItem } from '../../type/common';
import type { ScreenType, TypographyFontOption, TypographyV2Attrs, TypographyV2Props } from '../../types';
import FieldLabel from '../FieldLabel.vue';
import Select from '../Select.vue';
import DropdownInput from '../DropdownInput.vue';
import type { ColorProps } from '../global-color/types';
import ColorPickerModal from '../color-picker/ColorPickerModal.vue';
import { ENABLE_TEXT_SHADOW_ELEMENT, type FontItem, type FontName } from './types';
import Toggler from '../Toggler.vue';
import WrapGlobalShadow from './WrapGlobalShadow.vue';

const transformOptions: OptionItem[] = [
  {
    icon: `<svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M3.75 10C3.75 9.65482 4.02982 9.375 4.375 9.375H15.625C15.9702 9.375 16.25 9.65482 16.25 10C16.25 10.3452 15.9702 10.625 15.625 10.625H4.375C4.02982 10.625 3.75 10.3452 3.75 10Z" fill="currentColor"/>
</svg>
`,
    value: 'none',
  },
  {
    label: 'AA',
    value: 'uppercase',
  },
  {
    label: 'Aa',
    value: 'capitalize',
  },
  {
    label: 'aa',
    value: 'lowercase',
  },
];

const lineHeightOptions: any[] = [
  {
    label: '120%',
    value: '120%',
  },
  {
    label: '150%',
    value: '150%',
  },
  {
    label: '180%',
    value: '180%',
  },
  {
    label: '200%',
    value: '200%',
  },
];

type Props = {
  value?: TypographyV2Props;
  tag?: string;
  attrs?: TypographyV2Attrs;
  fontWeightOptions?: {
    label: string;
    value: string;
  }[];
  fontOptions?: TypographyFontOption[];
  fontUploads?: TypographyFontOption[];
  currentScreen?: ScreenType;
  hiddenSetting?: Record<keyof TypographyV2Attrs, boolean>;
  searchQuery?: string;
  currentGlobalStyleColor: any | undefined;
  globalColors: ColorProps[];
  sectionId: string;
  componentUid: string;
  currentComponentSetting?: any;
  globalStyleFont?: Record<string, FontItem>;
  shopId?: string;
  shopStore?: any;
  editorStore?: any;
  listCustomFont?: unknown[];
  themeFonts?: Partial<Record<FontName, FontItem>>;
  disableCollapse?: boolean;
};

const responsiveFields: (keyof TypographyV2Props | keyof TypographyV2Attrs)[] = ['fontSize', 'lineHeight'];

const props = withDefaults(defineProps<Props>(), {
  currentScreen: 'desktop',
});

const collapsed = ref(true);

const emit = defineEmits<{
  (e: 'saveColor', newColorList: {}): void;
  (e: 'changeScreen', screenId: ScreenType): void;
  (e: 'onChange', name: string, value: any, propType?: 'custom' | 'attrs'): void;
  (e: 'change', name: string, value: any, propType?: 'custom' | 'attrs'): void;
}>();

const onSaveColor = (newColorList: {}) => emit('saveColor', newColorList);

const { handleSaveFontMeta, handleDeleteFont, handleChangeFile, goToPricing, isFreePlan } =
  inject('handle-custom-font');

const emitChange = (
  name: string,
  value: any,
  args: { propType?: 'custom' | 'attrs'; type?: 'change' | 'onChange' },
) => {
  const propType = args.propType ?? 'custom';
  const type = args.type ?? 'change';
  if (name === 'fontSize') value = `${value}px`;
  if (responsiveFields.includes(name as keyof TypographyV2Props)) {
    const currentValue: any = props.value?.[name as keyof TypographyV2Props];
    value = {
      ...currentValue,
      [props.currentScreen]: value,
    };
  }

  switch (type) {
    case 'change':
      emit('change', name, value, propType);
      break;
    case 'onChange':
      emit('onChange', name, value, propType);
      break;
    default:
      break;
  }
};

const arrSearchKeyword = computed(() => props.searchQuery?.toLowerCase().split(' ') || []);
const isResultSearch = computed(() => {
  if (!props.searchQuery) return false;
  const settingKeyword = 'styles, font, size, color, font weight, line height, letter spacing, transform'.toLowerCase();
  return arrSearchKeyword.value.findIndex((item) => settingKeyword.includes(item)) > -1;
});

watchEffect(() => {
  collapsed.value = !props.disableCollapse;
});

const isShowSettingBySearch = (settingID: String) => {
  if (!isResultSearch.value) return true;
  return (
    isResultSearch.value && arrSearchKeyword.value.findIndex((item) => settingID.toLowerCase().includes(item)) > -1
  );
};

const handleChange = (name: string, value: any) => {
  emitChange(name, value, { propType: 'custom', type: 'change' });
};
const handleChangeToggle = (controlId?: string | undefined, value?: any) => {
  if (controlId) handleChange(controlId, value);
};

const handleChangeAttr = (name: string, value: any) => {
  emitChange(name, value, { propType: 'attrs', type: 'change' });
};

const onControlChangeToggle = (controlId?: string | undefined, value?: any) => {
  if (controlId) onControlChange(controlId, value);
};

const onControlChange = (name: string, value?: any) => {
  emitChange(name, value, { propType: 'custom', type: 'onChange' });
};
const onControlChangeAttr = (name: string, value?: any) => {
  emitChange(name, value, { propType: 'attrs', type: 'onChange' });
};

const fontWeightValue = computed(() => {
  if (props.fontWeightOptions && props.fontWeightOptions.length > 0) {
    const findFontWeight = props.fontWeightOptions?.find((item) => item.value === props.value?.fontWeight);

    return findFontWeight?.value || props.fontWeightOptions[0].value;
  }
  return undefined;
});

const toggleProp = (name: keyof Pick<TypographyV2Attrs, 'bold' | 'italic' | 'underline'>) => {
  handleChangeAttr(name, !props.attrs?.[name]);
};

const letterSpacing = computed(() => {
  if (!props.value?.letterSpacing || props.value?.letterSpacing === 'normal') return '0px';
  return props.value?.letterSpacing;
});

const fontSize = computed(() => {
  const value = getResponsiveValueByScreen(props.value?.fontSize, props.currentScreen);
  return value ? parseFloat(value) : undefined;
});
const lightHeight = computed(() => {
  const value = getResponsiveValueByScreen(props.value?.lineHeight, props.currentScreen);
  return value;
});
</script>
<template>
  <div class="flex flex-col gap-16" data-test="editor-control-all-font-styles">
    <div
      v-if="isShowSettingBySearch('Text Font Family')"
      class="flex items-center justify-between gap-16"
      data-test="editor-control-font-family">
      <label class="text-12 text-text-dark-300 whitespace-nowrap">Font</label>
      <div class="max-w-input-horizontal w-full">
        <FontFamilySelect
          id="fontFamily"
          :options="fontOptions"
          :value="value?.fontFamily"
          :current-component-setting="currentComponentSetting"
          :global-style-font="globalStyleFont"
          :editor-store="editorStore"
          :shop-store="shopStore"
          :is-free-plan="isFreePlan"
          :font-uploads="fontUploads"
          :theme-fonts="themeFonts"
          @control-change="handleChange"
          @handle-change-file="handleChangeFile"
          @handle-delete-font="handleDeleteFont"
          @handle-save-font-meta="handleSaveFontMeta"
          @go-to-pricing="goToPricing">
        </FontFamilySelect>
      </div>
    </div>
    <div
      v-if="isShowSettingBySearch('Text Font Size')"
      class="flex items-start justify-between gap-16"
      data-test="editor-control-font-size-style">
      <FieldLabel
        label="Size"
        :has-device="responsiveFields.includes('fontSize')"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <div class="max-w-input-horizontal flex w-full flex-col gap-16">
        <InputUpDownNumber
          id="fontSize"
          :value="fontSize"
          :min="0"
          :control-change="handleChange"
          @control-on-change="onControlChange" />
        <div data-test="editor-control-typo-select-style" class="text-light-450 flex justify-end gap-8">
          <button
            v-if="!hiddenSetting?.bold"
            data-test="editor-control-typo-select-style-bold"
            class="relative inline-flex aspect-square w-36 items-center justify-center overflow-hidden rounded-xl before:absolute before:inset-0 before:transition-all hover:before:bg-white/20"
            :class="{
              'bg-dark-300': attrs?.bold,
            }"
            @click="toggleProp('bold')">
            <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.21387 17.5C4.66158 17.5 4.21387 17.0523 4.21387 16.5L4.21387 3.5C4.21387 2.94772 4.66158 2.5 5.21387 2.5L10.2197 2.5C11.3232 2.5 12.2437 2.66357 12.981 2.99072C13.7183 3.31787 14.2725 3.77197 14.6436 4.35303C15.0146 4.9292 15.2002 5.59326 15.2002 6.34522C15.2002 6.93115 15.083 7.44629 14.8486 7.89063C14.6143 8.33008 14.292 8.69141 13.8818 8.97461C13.4766 9.25293 13.0127 9.45068 12.4902 9.56787V9.71435C13.0615 9.73877 13.5962 9.8999 14.0942 10.1978C14.5972 10.4956 15.0049 10.9131 15.3174 11.4502C15.6299 11.9824 15.7861 12.6172 15.7861 13.3545C15.7861 14.1504 15.5884 14.8608 15.1929 15.4858C14.8022 16.106 14.2236 16.5967 13.457 16.958C12.6904 17.3193 11.7456 17.5 10.6226 17.5H5.21387ZM7.38525 14.9072L9.9707 14.9072C10.8545 14.9072 11.499 14.7388 11.9043 14.4019C12.3096 14.0601 12.5122 13.606 12.5122 13.0396C12.5122 12.6245 12.4121 12.2583 12.2119 11.9409C12.0117 11.6235 11.7261 11.3745 11.355 11.1938C10.9888 11.0132 10.5518 10.9229 10.0439 10.9229L7.38525 10.9229V14.9072ZM7.38525 8.77686H9.73633C10.1709 8.77686 10.5566 8.70117 10.8936 8.5498C11.2354 8.39356 11.5039 8.17383 11.6992 7.89063C11.8994 7.60742 11.9995 7.26807 11.9995 6.87256C11.9995 6.33057 11.8066 5.89355 11.4209 5.56152C11.04 5.22949 10.498 5.06348 9.79492 5.06348L7.38525 5.06348L7.38525 8.77686Z"
                fill="currentColor" />
            </svg>
          </button>
          <button
            v-if="!hiddenSetting?.italic"
            data-test="editor-control-typo-select-style-italic"
            class="relative inline-flex aspect-square w-36 items-center justify-center overflow-hidden rounded-xl before:absolute before:inset-0 before:transition-all hover:before:bg-white/20"
            :class="{
              'bg-dark-300': attrs?.italic,
            }"
            @click="toggleProp('italic')">
            <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M7.48538 17.4999H2.5C2.15482 17.4999 1.875 17.22 1.875 16.8749C1.875 16.5297 2.15482 16.2499 2.5 16.2499H7.07219L11.9248 3.74986L7.5 3.74986C7.15482 3.74986 6.875 3.47004 6.875 3.12486C6.875 2.77968 7.15482 2.49986 7.5 2.49986L12.8235 2.49986C12.8331 2.49964 12.8428 2.49964 12.8524 2.49986L17.5 2.49986C17.8452 2.49986 18.125 2.77969 18.125 3.12486C18.125 3.47004 17.8452 3.74986 17.5 3.74986L13.2656 3.74986L8.41307 16.2499H12.5C12.8452 16.2499 13.125 16.5297 13.125 16.8749C13.125 17.22 12.8452 17.4999 12.5 17.4999H7.51433C7.5047 17.5001 7.49505 17.5001 7.48538 17.4999Z"
                fill="currentColor" />
            </svg>
          </button>
          <button
            v-if="!hiddenSetting?.underline"
            data-test="editor-control-typo-select-style-underline"
            class="relative inline-flex aspect-square w-36 items-center justify-center overflow-hidden rounded-xl before:absolute before:inset-0 before:transition-all hover:before:bg-white/20"
            :class="{
              'bg-dark-300': attrs?.underline,
            }"
            @click="toggleProp('underline')">
            <svg width="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M15.625 2.5C15.625 2.15483 15.3452 1.875 15 1.875C14.6548 1.875 14.375 2.15482 14.375 2.5L14.375 10.0011C14.375 12.4171 12.4164 14.375 10 14.375C7.58358 14.375 5.625 12.4171 5.625 10.0011L5.62501 2.5C5.62501 2.15483 5.34519 1.875 5.00001 1.875C4.65483 1.875 4.37501 2.15482 4.37501 2.5L4.375 10.0011C4.375 13.1078 6.89357 15.625 10 15.625C13.1064 15.625 15.625 13.1078 15.625 10.0011L15.625 2.5Z"
                fill="currentColor" />
              <path
                d="M3.75 16.875C3.40482 16.875 3.125 17.1548 3.125 17.5C3.125 17.8452 3.40482 18.125 3.75 18.125H16.25C16.5952 18.125 16.875 17.8452 16.875 17.5C16.875 17.1548 16.5952 16.875 16.25 16.875H3.75Z"
                fill="currentColor" />
            </svg>
          </button>
        </div>
      </div>
    </div>
    <div
      v-if="!hiddenSetting?.color && isShowSettingBySearch('Text Color')"
      class="flex items-center justify-between gap-16"
      data-test="editor-control-font-color">
      <FieldLabel
        label="Color"
        :has-device="responsiveFields.includes('color')"
        :current-screen="currentScreen"
        @change-screen="$emit('changeScreen', $event)" />
      <div class="max-w-input-horizontal w-full">
        <!-- <GlobalColorPicker
          id="color"
          :value="attrs?.color"
          @control-change="handleChangeAttr"
          @control-on-change="onControlChangeAttr" /> -->
        <ColorPickerModal
          id="color"
          mode="solid"
          :value="attrs?.color"
          :global-colors="globalColors"
          :current-global-style-color="currentGlobalStyleColor"
          @save-color="onSaveColor"
          @control-change="handleChangeAttr" />
      </div>
    </div>
    <template v-if="!collapsed || isResultSearch">
      <div
        v-if="isShowSettingBySearch('Text Font Weight')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-font-weight">
        <FieldLabel
          label="Font weight"
          :has-device="responsiveFields.includes('fontWeight')"
          :current-screen="currentScreen"
          @change-screen="$emit('changeScreen', $event)" />
        <div class="max-w-input-horizontal w-full">
          <Select
            id="fontWeight"
            disable-default
            :options="fontWeightOptions"
            :value="fontWeightValue"
            @control-change="handleChange" />
        </div>
      </div>
      <div
        v-if="isShowSettingBySearch('Text Line height')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-line-height">
        <FieldLabel
          label="Line height"
          :has-device="responsiveFields.includes('lineHeight')"
          :current-screen="currentScreen"
          @change-screen="$emit('changeScreen', $event)" />

        <DropdownInput
          id="lineHeight"
          :value="lightHeight"
          :display-options="lineHeightOptions"
          :units="['px', '%']"
          input-type="number"
          :hide-unit="false"
          @on-change="onControlChange"
          @change="handleChange" />
      </div>
      <div
        v-if="isShowSettingBySearch('Text Letter spacing')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-letter-spacing">
        <FieldLabel
          label="Letter spacing"
          :has-device="responsiveFields.includes('letterSpacing')"
          :current-screen="currentScreen"
          @change-screen="$emit('changeScreen', $event)" />
        <div class="max-w-input-horizontal w-full">
          <InputUnit
            id="letterSpacing"
            :units="['px']"
            :value="letterSpacing"
            :control-change="handleChange"
            @control-on-change="onControlChange" />
        </div>
      </div>
      <div
        v-if="!hiddenSetting?.transform && isShowSettingBySearch('Text Transform')"
        class="flex items-center justify-between gap-16"
        data-test="editor-control-text-transform">
        <FieldLabel
          label="Transform"
          :has-device="responsiveFields.includes('transform')"
          :current-screen="currentScreen"
          @change-screen="$emit('changeScreen', $event)" />
        <div class="max-w-input-horizontal w-full">
          <Segment
            id="transform"
            :value="attrs?.transform ?? 'none'"
            :options="transformOptions"
            @control-change="handleChangeAttr"
            @control-on-change="onControlChangeAttr" />
        </div>
      </div>
      <div v-if="tag && ENABLE_TEXT_SHADOW_ELEMENT.includes(tag)" class="group/toggle flex h-[36px] items-center">
        <p class="text-text-dark-300 font-regular text-12">Shadow</p>
        <Toggler
          id="hasShadowText"
          :value="props.value?.hasShadowText || false"
          @control-change="handleChangeToggle"
          @control-on-change="onControlChangeToggle" />
      </div>
      <div
        v-if="props.value?.hasShadowText"
        class="gemx-control flex min-h-[36px] w-full items-start justify-between gap-16">
        <div>
          <WrapGlobalShadow
            id="textShadow"
            layout="vertical"
            :value="props.value?.textShadow"
            :global-colors="globalColors"
            :current-global-style-color="currentGlobalStyleColor"
            @on-save-color="onSaveColor"
            @control-change="handleChange"
            @control-on-change="onControlChange" />
        </div>
      </div>
    </template>
    <GButtonV2
      v-if="!isResultSearch && !disableCollapse"
      data-test="editor-control-show-more-btn"
      type="secondary"
      size="medium"
      button-width="full"
      icon-view-box="0 0 16 16"
      :icon-after="collapsed ? 'chevron-down' : 'chevron-up'"
      @click="collapsed = !collapsed">
      {{ collapsed ? 'Show more' : 'Show less' }}
    </GButtonV2>
  </div>
</template>
