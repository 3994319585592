<script setup lang="ts">
import { InputUnit, InputMoney } from '@gem/control';
import { ref, computed } from 'vue';
import type { ScreenType } from '../types';
import { GInput } from '@gem/uikit';

type Props = {
  id: string;
  value?: string;
  hideUnit?: boolean;
  inputType?: 'text' | 'number' | 'money';
  units?: string[];
  displayOptions?: {
    label: string;
    value: string;
    reversed?: boolean;
    showValue?: boolean;
  }[];
  placeholder?: string;
  currentScreen?: ScreenType;
  globalStyleContainerWidth?: string;
  useOnlyUnitInit?: boolean;
  readonly?: boolean;
  min?: number;
  max?: number;
  defaultValue?: string;
};

const props = withDefaults(defineProps<Props>(), {
  hideUnit: true,
  inputType: 'text',
  readonly: false,
  placeholder: 'Auto',
  units: () => ['px'],
  min: Number.MIN_SAFE_INTEGER,
  max: Number.MAX_SAFE_INTEGER,
  defaultValue: 'default',
});

const inputTag = props.inputType === 'text' ? GInput : props.inputType === 'money' ? InputMoney : InputUnit;

const realValue = ref(props.value);

const globalStyleContainerWidth = computed<string>(() => props.globalStyleContainerWidth || '');

const displayValue = computed(() => {
  return props.value === 'default' ? (globalStyleContainerWidth.value as string) : props.value;
});

const emit = defineEmits<{
  (e: 'change', controlId: string, value?: string): void;
  (e: 'onChange', controlId: string, value?: any): void;
}>();

const handleInputChange = (type: 'change' | 'onChange', id: string, value?: string) => {
  updateRealValue(value);
  type === 'change' ? emit('change', id, realValue.value) : emit('onChange', id, realValue.value);
};

const handleChangeValue = (newValue: string) => {
  updateRealValue(newValue);
  emit('change', props.id, realValue.value);
  emit('onChange', props.id, realValue.value);
};

function controlChange(value?: string) {
  updateRealValue(value);
  if (props.inputType === 'text') emit('onChange', props.id, value ? value : realValue.value);
  else emit('change', props.id, value ? value : realValue.value);
}

const updateRealValue = (value?: any) => {
  realValue.value = isGlobalValue(value) ? props.defaultValue : value;
};

const isGlobalValue = (value?: string) => {
  return value === 'default' || value === globalStyleContainerWidth.value;
};
</script>

<template>
  <div class="max-w-input-horizontal w-full">
    <g-popover
      :has-arrow="false"
      :closeable="true"
      :overlay="false"
      overlay-container="#root-modal"
      placement="bottom-end"
      cls="!p-0">
      <template #default="{}">
        <component
          :is="inputTag"
          :id="id"
          ref="refInput"
          input-class="!w-full"
          :value="displayValue"
          :units="units"
          :min="min"
          :max="max"
          :use-only-unit-init="useOnlyUnitInit"
          :empty-on-clear="true"
          :readonly="readonly"
          :hide-unit="hideUnit"
          :placeholder="placeholder"
          :control-change="(id: string, value: string) => handleInputChange('change', id, value)"
          @control-on-change="(id: string, value: string) => handleInputChange('onChange', id, value)"
          @handle-blur="props.inputType === 'text' ? controlChange : undefined"
          @on-change="props.inputType === 'text' ? controlChange : undefined"
          @change="props.inputType === 'text' ? controlChange : undefined"></component>
      </template>
      <template v-if="displayOptions?.length" #content="{ close }">
        <div class="rounded-12 bg-dark-400 shadow-4dp w-[180px] p-8 transition-all">
          <template v-for="(opt, index) in displayOptions" :key="index">
            <div
              class="text-12 hover:bg-light-100/20 text-light-100 relative flex h-36 w-full cursor-pointer items-center truncate whitespace-nowrap rounded-xl pl-40 pr-8"
              @click="
                () => {
                  handleChangeValue(opt.value);
                  close();
                }
              ">
              <span
                v-if="value?.toLocaleLowerCase() === opt.value.toLocaleLowerCase()"
                class="text-light-450 absolute inset-y-0 left-8 flex items-center">
                <GBaseIcon name="status-check" width="20" height="20" />
              </span>
              <p class="flex w-full items-center justify-between truncate">
                <span class="truncate">{{ opt.label }}</span>
                <span v-if="opt.showValue" class="text-text-dark-300">{{
                  opt.value === 'default' ? globalStyleContainerWidth : opt.value
                }}</span>
              </p>
            </div>
          </template>
        </div>
      </template>
    </g-popover>
  </div>
</template>
