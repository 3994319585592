import type { ComputedRef, Ref } from 'vue';
import type { COLOR_PICKER_MODE_TYPE, TAB_TYPE } from '../types';

import { computed, onUnmounted, ref, watch } from 'vue';
import { useFloating, offset, shift, autoUpdate } from '@floating-ui/vue';

import { GRADIENT_TAB, getBottomPosition, getMaxModalHeight } from '../helpers';
import useColorStore from './useColorStore';

const useModalControl = (
  mode: ComputedRef<COLOR_PICKER_MODE_TYPE>,
  openColorPicker: Ref<boolean>,
  activeTab: Ref<TAB_TYPE>,
  myColors: ComputedRef<string[]>,
  recentGradientColors: ComputedRef<string[]>,
  currentColor: ComputedRef<string | undefined>,
  currentGlobalStyleColor: ComputedRef<Object | undefined>,
  composeColors: ComputedRef<string>,
  onSaveColor: (newColorList: {}) => void,
) => {
  const hasColorChange = ref(false);
  const openColorModal = ref(false);
  const isFixedModal = ref(false);
  const modalRoot = ref<HTMLElement | null>(null);
  const modalContent = ref<HTMLElement | null>(null);
  const recordChangeToStore = ref(false);

  const modalStyle = computed(() =>
    isFixedModal.value
      ? getBottomPosition(
          mode.value,
          openColorPicker.value,
          activeTab.value,
          myColors.value.length,
          recentGradientColors.value.length,
        )
      : '',
  );

  const saveChangeToStore = useColorStore(onSaveColor, currentGlobalStyleColor);
  const { y, strategy } = useFloating(modalRoot, modalContent, {
    placement: 'bottom',
    middleware: [offset(9), shift()],
    whileElementsMounted: autoUpdate,
  });

  const onToggleColorModal = (onlyClose?: boolean) => {
    if (!modalRoot.value) return;

    const maxModalHeight = getMaxModalHeight(myColors.value.length, recentGradientColors.value.length);
    const viewPortBottomSpaceLeft =
      window.innerHeight - (modalRoot?.value as HTMLElement).getBoundingClientRect().bottom;
    isFixedModal.value = maxModalHeight > viewPortBottomSpaceLeft;
    if (onlyClose) {
      openColorModal.value = false;
    } else {
      openColorModal.value = !openColorModal.value;
    }

    if (openColorModal.value) return (recordChangeToStore.value = false);

    recordChangeToStore.value = true;
    saveChangeToStore(
      activeTab.value,
      activeTab.value === GRADIENT_TAB ? currentColor.value : composeColors.value,
      recentGradientColors.value,
      myColors.value,
    );
  };

  onUnmounted(() => {
    if (!recordChangeToStore.value && hasColorChange.value) {
      saveChangeToStore(
        activeTab.value,
        activeTab.value === GRADIENT_TAB ? currentColor.value : composeColors.value,
        recentGradientColors.value,
        myColors.value,
      );
    }
  });

  watch(currentColor, () => (hasColorChange.value = true));

  return {
    y,
    modalRoot,
    modalContent,
    openColorModal,
    modalStyle,
    strategy,
    recordChangeToStore,
    onToggleColorModal,
  };
};

export default useModalControl;
