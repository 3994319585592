import { getSizeFromRatioValue, isAuto, isCustomShape, isPercent } from '../../helpers/globalSize';
import type { PaddingType } from '../distance-picker/types';
import type { SettingConfig, SettingID, ShapeConfig, ShapeOptions, SizeSettingProps, SizeSettingValue } from './type';
import { type ComputedRef, type Ref, computed } from 'vue';

const useUpdateDataSizeSetting = (props: ComputedRef<SizeSettingProps>) => {
  const propValue = computed<SizeSettingValue | undefined>(() => props.value.value);
  const propSettingConfig = computed<Record<SettingID, SettingConfig> | undefined>(() => props.value.settingConfig);
  const globalStyleContainerWidth = computed(() => props.value.globalStyleContainerWidth);
  const customShapeValue = computed(() => props.value.value?.customShapeValue);
  const lockShapeValue = computed(() => props.value.lockShapeValue && isHiddenSetting('proportion'));

  const shapeConfig = computed<ShapeConfig>(() => {
    const shapeConfigProp = propSettingConfig?.value?.shape?.shapeConfig;
    const shapeConfigDefault: ShapeConfig = {
      vertical: '3/4',
      horizontal: '4/3',
      square: '1/1',
    };
    return shapeConfigProp
      ? {
          ...shapeConfigProp,
          square: '1/1',
        }
      : shapeConfigDefault;
  });

  const linked = computed(() =>
    lockShapeValue.value
      ? propValue.value?.shapeLinked
      : propValue.value?.shapeLinked || propValue.value?.shape !== 'custom' || false,
  );

  const isSupportShape = computed(
    () => !isHiddenSetting('shape') && !isHiddenSetting('width') && !isHiddenSetting('height'),
  );

  function isHiddenSetting(settingID: SettingID) {
    if (settingID === 'shape' && propValue.value?.hiddenShape !== undefined) {
      return propValue.value?.hiddenShape;
    }
    return props.value.hiddenSettings?.includes(settingID);
  }

  const getValueWhenUpdateSetting = (
    settingID: string,
    value?: string | PaddingType | boolean,
    previousShapeValue?: Ref<ShapeOptions | undefined>,
    type?: 'change' | 'onChange',
  ): SizeSettingValue => {
    switch (settingID) {
      case 'height':
        return getValueWhenUpdateHeight(value as string, previousShapeValue, type);
      case 'shape':
        return getValueWhenUpdateShape(value as ShapeOptions);
      case 'width':
        return getValueWhenUpdateWidth(value as string, type);
      case 'padding':
        return getValueWhenUpdatePadding(value as PaddingType);
      case 'gap':
        return getValueWhenUpdateGap(value as string);
      case 'shapeLinked':
        return getValueWhenUpdateShapeLinked(value as boolean);
      default:
        return {};
    }
  };

  const getValueWhenUpdateGap = (value?: string): SizeSettingValue => {
    const newData = {
      ...propValue.value,
      gap: value,
    };
    return newData;
  };

  const getValueWhenUpdateShapeLinked = (value?: boolean): SizeSettingValue => {
    const newData = {
      ...propValue.value,
      shapeLinked: value,
    };
    updateShapeWhenShapeLinkedChange({ sizeData: newData, linked: value });
    return newData;
  };

  const updateShapeWhenShapeLinkedChange = (data: { sizeData: SizeSettingValue; linked?: boolean }) => {
    const { sizeData, linked } = data;
    const currentShape = sizeData.shape;
    if (lockShapeValue.value) {
      return;
    }
    if (currentShape !== 'custom' && !linked) {
      sizeData.shape = 'custom';
      sizeData.shapeValue = '';
      sizeData.customShapeValue = '';
    }
    if (currentShape === 'custom' && linked) {
      if (isAuto(sizeData.width || '') || isAuto(sizeData.height || '')) {
        sizeData.shape = 'original';
        sizeData.shapeLinked = true;
        sizeData.shapeValue = '';
      } else sizeData.customShapeValue = getShapeValueFormShapeConfig(sizeData.shape, sizeData);
    }
    if (currentShape === 'custom' && !linked) {
      sizeData.customShapeValue = '';
    }
  };

  const getValueWhenUpdateWidth = (value?: string, type?: 'change' | 'onChange'): SizeSettingValue => {
    const newData = {
      ...propValue.value,
      width: value || '',
    };
    if (isSupportShape.value && newData.height !== '100vh' && newData.height !== 'fit-content') {
      updateShapeWhenWidthChange({ sizeData: newData, width: value, type });
      updateHeightWhenWidthChange(newData, value);
    }
    return newData;
  };

  const getValueWhenUpdatePadding = (padding?: PaddingType): SizeSettingValue => {
    return {
      ...propValue.value,
      padding: padding,
    };
  };

  const isFitContentHeight = (value?: string) => {
    return (
      propValue.value?.shape === 'custom' &&
      propValue.value.height === 'fit-content' &&
      value?.toLocaleLowerCase() === 'auto'
    );
  };

  const getValueWhenUpdateHeight = (
    value?: string,
    previousShapeValue?: Ref<ShapeOptions | undefined>,
    type?: 'change' | 'onChange',
  ): SizeSettingValue => {
    const newHeight = isFitContentHeight(value) ? 'fit-content' : value || '';
    const newData = {
      ...propValue.value,
      height: newHeight,
    };
    if (isSupportShape.value) {
      updateShapeWhenHeightChange({
        sizeData: newData,
        height: newHeight,
        previousShapeValue,
        type,
      });
      updateWithWhenHeightChange(newData, value);
    }
    return newData;
  };

  const updateWithWhenHeightChange = (sizeData: SizeSettingValue, height?: string) => {
    const shapeValue = sizeData.shapeValue;
    const isHeightAuto = isAuto(height || '');
    if (height === '100%') {
      return;
    }
    if ((height === 'fit-content' || height === '100vh') && !sizeData.width) {
      sizeData.width = '100%';
      return;
    }
    if (!isCustomShape(sizeData)) {
      if (linked?.value && shapeValue) {
        sizeData.width =
          !height || isHeightAuto
            ? ''
            : getSizeFromRatioValue(shapeValue, {
                from: 'height',
                value: height,
              });
      }
    } else {
      if (linked?.value) {
        const isOriginalRatio = sizeData.shape === 'original';
        if (isOriginalRatio && !isHeightAuto) {
          sizeData.width = '';
        }
        if (!isOriginalRatio && shapeValue) {
          sizeData.width =
            isHeightAuto || !height
              ? ''
              : getSizeFromRatioValue(shapeValue, {
                  from: 'height',
                  value: height,
                });
          return;
        }
      }
    }
  };

  const updateHeightWhenWidthChange = (sizeData: SizeSettingValue, width?: string) => {
    const shapeValue = sizeData.shapeValue;
    const isWidthPercent = isPercent(width || '');
    const isWidthAuto = isAuto(width || '');
    if (!isCustomShape(sizeData)) {
      if (linked?.value) {
        sizeData.height =
          isWidthPercent || !width || isWidthAuto
            ? ''
            : getSizeFromRatioValue(shapeValue || '1/1', {
                from: 'width',
                value: width,
              });
        return;
      }
    } else {
      const isOriginalRatio = sizeData.shape === 'original';
      if (linked?.value) {
        if (!isOriginalRatio && shapeValue) {
          sizeData.height =
            isWidthAuto || isWidthPercent || !width
              ? ''
              : getSizeFromRatioValue(shapeValue, {
                  from: 'width',
                  value: width,
                });
          return;
        }
        if (isOriginalRatio && !isWidthAuto) {
          sizeData.height = '';
        }
      }
    }
  };

  const updateShapeWhenWidthChange = (data: {
    sizeData: SizeSettingValue;
    width?: string;
    type?: 'change' | 'onChange';
  }) => {
    const { sizeData, width, type } = data;
    if (lockShapeValue.value) return;
    if (
      isAuto(width || '') &&
      isCustomShape(sizeData) &&
      type == 'change' &&
      !sizeData.customShapeValue &&
      sizeData.height !== 'fit-content' &&
      sizeData.height !== '100vh'
    ) {
      sizeData.shape = 'original';
      sizeData.shapeLinked = true;
      sizeData.shapeValue = '';
    }
    sizeData.shapeValue = getShapeValueFormShapeConfig(sizeData.shape, sizeData);
  };

  const getDefaultValue = (value?: string) => {
    if (value === 'default') return globalStyleContainerWidth.value;
    return value;
  };

  const updateShapeWhenHeightChange = (data: {
    sizeData: SizeSettingValue;
    height?: string;
    previousShapeValue?: Ref<ShapeOptions | undefined>;
    type?: 'change' | 'onChange';
  }) => {
    const { sizeData, height, type } = data;
    const isExistValue = height && !isAuto(height || 'null');
    if (lockShapeValue.value) {
      sizeData.shapeLinked = height === '100%' ? false : sizeData.shapeLinked;
      return;
    }
    if (
      !isExistValue &&
      isCustomShape(sizeData) &&
      type == 'change' &&
      !sizeData.customShapeValue &&
      height !== 'fit-content'
    ) {
      sizeData.shape = 'original';
      sizeData.shapeLinked = true;
      sizeData.shapeValue = '';
      return;
    }
    if (height === 'fit-content' || height === '100vh') {
      sizeData.shape = 'custom';
      sizeData.shapeLinked = false;
      sizeData.shapeValue = '';
      return;
    }
    sizeData.shapeValue = getShapeValueFormShapeConfig(sizeData.shape, sizeData);
  };

  const getValueWhenUpdateShape = (value: ShapeOptions): SizeSettingValue => {
    const newData: SizeSettingValue = {
      ...propValue.value,
      shape: value,
      shapeValue: getShapeValueFormShapeConfig(value, propValue.value),
    };
    updateWidthHeightWhenShapeChange({ sizeData: newData, shape: value });
    updateShapeLinkedWhenShapeChange({ sizeData: newData, shape: value });
    return newData;
  };

  const updateWidthHeightWhenShapeChange = (data: { sizeData: SizeSettingValue; shape?: string }) => {
    const { sizeData, shape } = data;
    switch (shape) {
      case 'original':
        sizeData.width = '';
        sizeData.height = '';
        break;
      case 'custom':
        if (customShapeValue.value) {
          if (sizeData.width && !isPercent(sizeData.width) && !isAuto(sizeData.width)) {
            sizeData.height = getSizeFromRatioValue(customShapeValue.value, {
              from: 'width',
              value: sizeData.width,
            });
          } else {
            sizeData.height = '';
          }
        }
        break;
      default:
        if (sizeData.shapeValue) {
          if (sizeData.width && !isPercent(sizeData.width) && !isAuto(sizeData.width)) {
            sizeData.height = getSizeFromRatioValue(sizeData.shapeValue, {
              from: 'width',
              value: sizeData.width,
            });
          } else {
            sizeData.height = '';
          }
        }
    }
  };

  const updateShapeLinkedWhenShapeChange = (data: { sizeData: SizeSettingValue; shape?: string }) => {
    const { sizeData, shape } = data;
    if (shape && shape !== 'custom') {
      sizeData.shapeLinked = true;
    }
    if (shape === 'custom') {
      sizeData.shapeLinked = sizeData.customShapeValue ? true : false;
    }
  };

  const getShapeValueFormShapeConfig = (shape?: ShapeOptions, sizeData?: SizeSettingValue): string | undefined => {
    let shapeValue = sizeData?.customShapeValue || sizeData?.shapeValue;
    const width = getDefaultValue(sizeData?.width);
    const height = getDefaultValue(sizeData?.height);
    if (shape && shape !== 'custom') shapeValue = shapeConfig?.value?.[shape];
    if (
      shape == 'custom' &&
      !linked?.value &&
      !isPercent(width || '') &&
      !isPercent(height || '') &&
      !isAuto(width || '') &&
      !isAuto(height || '')
    ) {
      shapeValue = `${parseInt(width || '1')}/${parseInt(height || '1')}`;
    }
    if (
      shape == 'custom' &&
      !linked?.value &&
      (isAuto(width || '') || isAuto(height || '') || isPercent(width || '') || isPercent(height || ''))
    ) {
      shapeValue = '';
    }
    return shapeValue;
  };

  return {
    getValueWhenUpdateSetting,
  };
};

export default useUpdateDataSizeSetting;
