import type { TypographyFamily, TypographyFontOption } from '@gem/common';
import type { Ref } from 'vue';
import { inject, computed } from 'vue';

export const useCheckIsRecentFont = () => {
  const pageMetaRecentlyValueParse = inject<Ref<TypographyFamily>>('pageMetaRecentlyValueParse');

  const recentFonts = computed(() => {
    const value = pageMetaRecentlyValueParse?.value;
    return Array.isArray(value) ? value : [];
  });

  const isRecentFont = (font: TypographyFontOption): boolean => {
    return recentFonts.value?.some((recentFont) => {
      const recentFontValue = getRecentFontValue(recentFont);
      return font.value === recentFontValue;
    });
  };

  return { isRecentFont };
};

export function getRecentFontValue(recentFont: any): string {
  return typeof recentFont.value === 'string' ? recentFont.value : recentFont.value.value;
}
