<script lang="ts" setup>
import type { File as ImageFile } from '../../type/graphql';
import type { GlobalSwatchesData, SwatchesOptionType, SwatchesOptionValue } from '@gem/common';

import { ref, watch, inject } from 'vue';

import SwatchesPreviewImage from './components/SwatchesPreviewImage.vue';
import SwatchesItemImage from './components/SwatchesItemImage.vue';
import { useInfiniteScroll } from '@vueuse/core';

const emit = defineEmits<{
  (e: 'changeData', value: SwatchesOptionValue[], index: string): void;
  (e: 'uploadImage', formData: FormData, index?: string, toVariant?: string): void;
  (e: 'typeChange', index: number, type: SwatchesOptionType): void;
  (e: 'handleAlert', msg: string): void;
  (e: 'fetchNextPage'): void;
  (e: 'acceptWarning'): void;
}>();

const props = withDefaults(
  defineProps<{
    data?: GlobalSwatchesData[];
    index: number;
    images?: ImageFile[];
    maximumSize?: number;
    allowedFiles?: string[];
    shopId: string;
    optionTitle?: string;
    saveClicked: boolean;
    isDataDiff: boolean;
    showOther: boolean;
    isImageNotSet: boolean;
    shopifyPlan?: string;
    isInstant?: boolean;
  }>(),
  {
    allowedFiles: () => ['image/jpeg', 'image/gif', 'image/png', 'image/webp', 'image/svg+xml'],
    maximumSize: 10 * 1024 * 1024, // 10MB
    data: () => [],
    showOther: false,
    isImageNotSet: false,
  },
);

const dataClone = ref<GlobalSwatchesData[]>(props.data);
const scrollSection = ref();
const uploadingSlot = inject<Set<number>>('uploadingSlot');

const handleChangeFile = (event: Event, index: string) => {
  const target = event.target as HTMLInputElement;
  if (!target.files?.length) return;
  const file = target.files.item(0);
  if (!file) return;

  if (!props.allowedFiles.includes(file.type)) {
    emit('handleAlert', 'File type is not supported, only support image type');
    return;
  }

  if (file.size > props.maximumSize) {
    emit('handleAlert', `File size is too large, maximum size is ${Math.round(props.maximumSize / 1024 / 1024)}MB`);
    return;
  }

  postUploadImage(file, index);
};

const postUploadImage = async (_urlImage: File, index: string) => {
  const formData = new FormData();
  formData.append('file', _urlImage);
  formData.append('shopID', props.shopId);
  formData.append('shopifyPlan', props.shopifyPlan ?? '');
  formData.append('fileType', 'GALLERY');
  formData.append('storage', props.isInstant ? 'GEMX' : 'SHOPIFY');

  emit('uploadImage', formData, index, props.index.toString());
};

useInfiniteScroll(scrollSection, () => {
  emit('fetchNextPage');
});

watch(
  () => props.data,
  () => {
    dataClone.value = props.data;
  },
);

watch(
  () => props.isImageNotSet,
  () => {
    if (!scrollSection.value?.$el) {
      return;
    }
    scrollSection.value.$el.scrollTop = 0;
  },
);
</script>

<template>
  <div class="flex">
    <perfect-scrollbar ref="scrollSection" class="flex !h-[554px] shrink-0">
      <div class="w-[360px] p-24">
        <div>
          <span class="text-text-light-300 text-12 font-medium">Value of this product</span>
          <div v-if="isImageNotSet" class="text-small rounded-medium flex bg-yellow-100 p-12">
            <g-base-icon
              class="icon-padding shrink-0"
              name="Warning"
              width="18"
              height="16"
              view-box="0 0 16 16"
              custom-class="text-red-400"></g-base-icon>
            <span class="leading-5 text-red-400"
              >There were blank options. You should pick color to display this product properly</span
            >
          </div>
          <div v-if="dataClone[index]">
            <template v-for="(option, i) in dataClone[index].optionValues" :key="i">
              <SwatchesItemImage
                v-if="!option.other"
                :option="option"
                :show-error="isImageNotSet"
                :is-uploading="uploadingSlot?.has(i)"
                :index="i"
                @handle-change-file="handleChangeFile">
              </SwatchesItemImage>
            </template>
          </div>
        </div>
        <div v-if="showOther" class="mt-16">
          <span class="text-text-light-300 text-12 font-medium">Other value</span>
          <div class="pb-24">
            <template v-for="(option, i) in dataClone[index].optionValues" :key="i">
              <SwatchesItemImage v-if="option.other" :option="option" :index="i" @handle-change-file="handleChangeFile">
              </SwatchesItemImage>
            </template>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <SwatchesPreviewImage />
  </div>
</template>

<style lang="scss">
.icon-padding {
  margin: 3px 9px 3px 3px;
}
</style>
